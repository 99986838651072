import React, { useEffect, useState } from "react";
import { SubmitSpinner } from "src/components/common";
import RouteContent from "src/components/common/RouteContent";
import {
  InitialPageDataContextProvider,
  useInitialPageDataContext,
} from "src/contexts/InitialPageDataContext";
import { availableDaysToString, instrumentsToString } from "src/utils/helpers";
import useTeacherData from "../SuperAdmin/TeachersPage/hooks/useTeacherData";
import DropdownFilter from "src/components/common/DropdownFilter";
import { FaSortAlphaDown } from "react-icons/fa";
import { FaSortAlphaUpAlt } from "react-icons/fa";
import {
  FilterContainer,
  Image,
  SortButton,
  TD,
  TH,
  THead,
  Table,
  TableWrapper,
} from "./TeacherAndStaffDirectory.styled";
import instrumentsIconsEnum from "src/constants/instrumentsIconsEnum";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import SearchBar from "../../common/Form/SearchBar/SearchBar";
import { Col, Row } from "reactstrap";

function TeacherAndStaffDirectory() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedInstruments, setSelectedInstruments] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const teacherData = useTeacherData();
  const { locations, instruments } = useInitialPageDataContext();
  const {
    loading,
    setSearchTerm,
    displayedTeachers,
    handleSelectedLocationsChange,
    handleSelectedInstrumentsChange,
  } = teacherData;
  const [sortedTeachers, setSortedTeachers] = useState(displayedTeachers);

  useEffect(() => {
    handleSelectedLocationsChange(selectedLocation ? [selectedLocation] : []);
    handleSelectedInstrumentsChange(
      selectedInstruments ? selectedInstruments : []
    );
  }, [selectedLocation, selectedInstruments]);

  useEffect(() => {
    setSortedTeachers(displayedTeachers);
  }, [displayedTeachers]);

  const handleSort = () => {
    const sorted = [...sortedTeachers].sort((a, b) => {
      const nameA = a.fullName.toLowerCase();
      const nameB = b.fullName.toLowerCase();
      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
    setSortedTeachers(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <RouteContent title="Teacher Directory">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <SubmitSpinner
            style={{ width: "3rem", height: "3rem", color: "#681e46" }}
          />
        </div>
      ) : (
        <div>
          <Row>
            <Col xs={12} xl={6} className="mb-3">
              <div id="teacher_directory">
                <SearchBar
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </Col>
            <Col xs={12} xl={3} className="mb-3">
              <DropdownFilter
                placeholder={"Select Location"}
                value={selectedLocation}
                setValue={setSelectedLocation}
                options={locations.map((location) => {
                  const label = location.name;
                  const value = location.id;
                  return {
                    label,
                    value,
                  };
                })}
                width="auto"
              />
            </Col>
            <Col xs={12} xl={3} className="mb-3">
              <DropdownFilter
                placeholder="Select instruments"
                value={selectedInstruments}
                setValue={setSelectedInstruments}
                options={instruments.map((instrument) => {
                  const label = instrument?.name;
                  const value = instrument?.id;
                  return {
                    label: (
                      <span>
                      {instrumentsIconsEnum[label]} {label}
                    </span>
                    ),
                    value,
                  };
                })}
                isSearchable
                isMulti
                width="auto"
              />
            </Col>
          </Row>
          <TableWrapper>
            <Table>
              <THead>
                <tr>
                  <TH>Photo</TH>
                  <TH
                    style={{
                      display: "flex",
                      gap: 24,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>Name</span>
                    <SortButton
                      onClick={handleSort}
                      aria-label="Sort by alphabet"
                      title="Sort by alphabet"
                    >
                      {sortOrder === "asc" ? (
                        <FaSortAlphaDown size={24} />
                      ) : (
                        <FaSortAlphaUpAlt size={24} />
                      )}
                    </SortButton>
                  </TH>
                  <TH>Instrument</TH>
                  <TH>Teaching Day</TH>
                  <TH>Phone</TH>
                  <TH>Email</TH>
                </tr>
              </THead>
              <tbody>
                {sortedTeachers.map((teacher) => {
                  const teacherInstrumentsNames = teacher.instrumentsInfo?.map(
                    (el) => el?.instrument?.name
                  );
                  return (
                    <tr key={teacher.id}>
                      <TD>
                        <Image
                          src={teacher.imageUrl || UnknownProfilePhoto}
                          alt={teacher?.fullName || "teacher"}
                        />
                      </TD>
                      <TD>{teacher.fullName}</TD>
                      <TD>
                        {instrumentsToString(teacherInstrumentsNames) || "N/A"}
                      </TD>
                      <TD>
                        {availableDaysToString(
                          teacher.teacherDays?.availableDays
                        )}
                      </TD>
                      <TD>{teacher.primaryPhone || "N/A"}</TD>
                      <TD>{teacher.primaryEmail || "N/A"}</TD>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableWrapper>
        </div>
      )}
    </RouteContent>
  );
}

const TeacherAndStaffDirectoryWrapper = () => (
  <InitialPageDataContextProvider>
    <TeacherAndStaffDirectory />
  </InitialPageDataContextProvider>
);

export default TeacherAndStaffDirectoryWrapper;
