import { createContext } from "react";

export const ActiveTabContext = createContext({
  activeTab: "musicSheets",
  setActiveTab: (tab) => {},
  isTeacher: false,
  isAdmin: false,
});
export const LoadingContext = createContext({
  loading: false,
  setLoading: (state) => {},
});
export const ItemsSearchContext = createContext({
  itemsSearchValue: "",
  setItemsSearchValue: (value) => {},
});
export const StudentsSearchContext = createContext({
  studentsSearchValue: "",
  setStudentsSearchValue: (value) => {},
});
export const SearchContext = createContext({
  searchValue: "",
  setSearchValue: (value) => {},
});
export const AddItemModalContext = createContext({
  isOpen: false,
  open: () => {},
  close: () => {},
});
export const ShareItemModalContext = createContext({
  isOpen: false,
  openShareModal: () => {},
  closeShareModal: () => {},
  submitShare: () => {},
});
export const ViewItemModalContext = createContext({
  state: {
    isOpen: false,
    item: {},
  },
  open: (item) => {},
  close: () => {},
});
export const DeleteItemsModalContext = createContext({
  state: {
    isOpen: false,
    items: [],
  },
  deleteItems: async (
    permanentDelete = false,
    deletePartiallyDeletedItemsOnly = false
  ) => {},
  close: () => {},
});
export const DownloadItemsModalContext = createContext({
  state: {
    isOpen: false,
    items: [],
  },
  downloadItem: () => {},
  moveItemsToUserLibrary: () => {},
  close: () => {},
});
export const RenameItemContext = createContext({
  state: {
    isOpen: false,
    item: "",
  },
  renameItem: async (itemId = "", newTitle = "") => {},
  close: () => {},
});
export const LibraryContext = createContext({
  library: {
    musicSheets: [],
    videos: [],
    recordings: [],
    recommendationLinks: [],
    studentsUploads: [],
  },
  selected: [],
  shared: [],
  add: (item) => {},
  deleteOne: (id) => {},
  deleteAll: () => {},
  toggle: () => {},
  select: (id) => {},
  shareOne: (id) => {},
  downloadOne: (id) => {},
  downloadAll: () => {},
  shareAll: () => {},
  openRenameItemModal: (id) => {},
});
export const StudentsSelectContext = createContext({
  selected: [],
  toggle: () => {},
  select: (id) => {},
  multiSelect: () => {},
  clear: () => {},
});

export const TeachersSelectContext = createContext({
  selected: [],
  toggle: () => {},
  select: (id) => {},
});

export const TeachersContext = createContext({
  teachers: [],
});

export const studentViews = {
  ALL: "all",
  TEACHER_STUDENTS: "teacher_students",
};
export const StudentsContext = createContext({
  students: [],
  teacherStudents: [],
  currentStudentsView: "",
});

export const AdminUserContext = createContext({
  selectedUserType: "all" | "school" | "teacher" | "student",
  setSelectedUserType: (val) => {},
  onUserSelect: (val) => {},
  selectUserType: (val) => {},
});

export const LessonNotesStudentsContext = createContext({
  students: [],
  selectedStudent: "",
  toggle: () => {},
  setSelectedStudent: (studentId) => {},
});
export const TeacherLessonNotesModalContext = createContext({
  isOpen: false,
  close: () => {},
  note: {},
  isEditingMode: false,
});
export const LessonNotesContext = createContext({
  isEditable: false,
  showAttachmentsModal: (note) => {},
  editNote: (note) => {},
  publishNote: async (noteId) => {},
  deleteNote: (noteId) => {},
  deleteAttachment: (attachmentIndex, noteId) => {},
  notes: [],
  students: [],
  lessons: [],
  userId: "",
});
export const NoteAttachmentsContext = createContext({
  isOpen: false,
  close: () => {},
  onSubmit: (files) => {},
});
export const DeleteNoteContext = createContext({
  isOpen: false,
  close: () => {},
  onDelete: () => {},
});
export const DeleteNoteAttachmentContext = createContext({
  isOpen: false,
  close: () => {},
  onDelete: () => {},
});
export const ViewHomeworkModalContext = createContext({
  isOpen: false,
  close: () => {},
  openHomework: (homework, noteId) => {},
  approveHomework: () => {},
  homework: {
    noteId: "",
    lesson: {
      date: new Date(),
      title: "",
    },
  },
  selectedUser: {
    name: "",
    id: "",
    avatar: "",
  },
  setSelectedUser: (studentId) => {},
});
export const SubmitHomeworkModalContext = createContext({
  isOpen: false,
  noteId: 0,
  close: () => {},
  open: (note) => {},
  submitHomework: (homework) => {},
});

export const ViewNoteModalContext = createContext({
  isOpen: false,
  note: {},
  close: () => {},
  open: (noteId) => {},
});

export const LessonDetailsModalContext = createContext({
  isOpen: false,
  lesson: {},
  close: () => {},
  open: (id) => {},
});
export const AdminFilterContext = createContext({
  setSelectedLocation: (id) => {},
  setSelectedStudent: (id) => {},
  setSelectedTeacher: (id) => {},
  selectedLocation: "",
  selectedTeacher: "",
  selectedStudents: "",
  onFilter: () => {},
  locations: [],
});
