import { isAdmin, isSuperAdmin, UserRole } from "src/constants/UserRoleEnum";
import { ListCheck } from "@styled-icons/bootstrap/ListCheck";
import styled from "styled-components";
import { sidebarContentTypes } from "src/constants/sidebarEnum";
import { canManageUsersPayment } from "src/utils/helpers";
import { MdOutlineFeedback } from "react-icons/md";
import { BsBellFill } from "react-icons/bs";

const ListCheckIcon = styled(ListCheck)`
  color: #fff;
  width: 25px;
  opacity: 0.8;
  font-weight: 600;
  margin-right: 5px;
  margin-left: -3px;
`;

const FeedbackIcon = styled(MdOutlineFeedback)`
  color: #fff;
  width: 25px;
  font-weight: 600;
`;

const BellIcon = styled(BsBellFill)`
  color: #fff;
  width: 25px;
  font-weight: 600;
`;

const routes = {
  dashboard: { text: "Dashboard", link: "/dashboard/home", icon: "home" },
  privateStudents: {
    text: "Private Students",
    link: "/dashboard/private-students",
    icon: "lock",
  },
  checkIn: {
    text: "Check In",
    link: "/dashboard/check-in",
    icon: "user-check",
  },
  trialLessons: {
    text: "Trial Lessons",
    link: "/dashboard/trial-lessons",
    icon: "clock",
  },
  profile: { text: "My profile", link: "/dashboard/profile", icon: "user" },
  teacherLibrary: {
    text: "Teacher Library",
    link: "/dashboard/teacher-library",
    icon: "book-open",
  },
  studentLibrary: {
    text: "Student Library",
    link: "/dashboard/student-library",
    icon: "book-open",
  },
  adminLibrary: {
    text: "Admin Library",
    link: "/dashboard/admin-library",
    icon: "book-open",
  },
  lessonNotes: {
    text: "Lessons Notes",
    link: "/dashboard/lesson-notes",
    icon: "user",
  },
  schedule: {
    text: "Schedule",
    link: "/dashboard/schedule",
    icon: "calendar-alt",
  },
  studentDashboard: {
    text: "Dashboard",
    link: "/dashboard/",
    icon: "home",
  },
  studentAbsences: {
    text: "Absence/Make Up",
    link: "/dashboard/absences",
    icon: "clock",
  },
  TeacherDashboard: {
    text: "Dashboard",
    link: "/dashboard/",
    icon: "home",
  },
  TeacherStudentList: {
    text: "StudentList",
    link: "/dashboard/StudentList",
    Styledicon: ListCheckIcon,
  },
  // Super Admin
  superAdminDashboard: {
    text: "Dashboard",
    link: "/dashboard/super-admin",
    icon: "home",
  },
  parentDashboard: {
    text: "Dashboard",
    link: "/dashboard/",
    icon: "home",
  },
  teachers: {
    text: "Teachers",
    link: "/dashboard/teachers",
    icon: "user",
  },
  recentPosts: {
    text: "Recent Posts",
    link: "/dashboard/recent-posts",
    icon: "address-card",
  },
  attendanceList: {
    text: "Attendance List",
    link: "/dashboard/attendance-list",
    icon: "check",
  },
  studentQR: {
    text: "View Codes",
    link: "/dashboard/student-code",
    icon: "eye",
  },
  schoolDates: {
    text: "School Dates",
    link: "/dashboard/school-dates",
    icon: "calendar-alt",
  },
  absenceLogs: {
    text: "A/M Logs",
    link: "/dashboard/absence-logs",
    icon: "lock",
  },
  teacherAbsences: {
    text: "Absence/Make Up",
    link: "/dashboard/teacher-absences",
    icon: "clock",
  },
  // Teacher Absence in admin dashboard
  teacherAbsence: {
    text: "Teacher Absence",
    link: "/dashboard/teacher-absence",
    icon: "clock",
  },
  makeUpNeeded: {
    text: "Make Up Needed",
    link: "/dashboard/makeup-needed",
    icon: "clock",
  },
  signup: {
    text: "Sign Up Users",
    link: "/signup",
    icon: "user",
  },
  schoolPolicies: {
    text: "School Policies",
    link: "/dashboard/school-policies",
    icon: "pen",
  },
  adminsPage: {
    text: "Admins",
    link: "/dashboard/admins",
    icon: "user",
  },
  parentsPage: {
    text: "Parents",
    link: "/dashboard/parents",
    icon: "user",
  },
  settings: {
    text: "Settings",
    link: "/settings",
    icon: "cog",
  },
  extraFunctions: {
    text: "Extra Functions",
    link: "/dashboard/extra-functions",
    icon: "cogs",
  },
  studentsMakeUp: {
    text: "Students Make Up",
    link: "/dashboard/students-makeup",
    icon: "chalkboard-teacher",
  },
  studioUsage: {
    text: "Studio Usage",
    link: "/dashboard/studio-usage",
    icon: "music",
  },
  teacherAndStaffDirectory: {
    text: "Teacher Directory",
    link: "/dashboard/teacher-and-staff-directory",
    icon: "chalkboard-teacher",
  },
  adminsPaymentPage: {
    text: "Payment Page",
    link: "/dashboard/admins-payment",
    icon: "dollar-sign",
  },
  applications: {
    text: "Applications",
    link: "/dashboard/applications",
    icon: "users",
  },
  myPaymentsPage: {
    text: "My Payment",
    link: "/dashboard/my-payments",
    icon: "dollar-sign",
  },
  makeupRequests: {
    text: "Make Up Requests",
    link: "/dashboard/makeup-requests",
    icon: "users",
  },
  groupClass: {
    text: "Group Class",
    link: "/dashboard/group-classes",
    icon: "chalkboard-teacher",
  },
  learningProfiles: {
    text: "Learning Profiles",
    link: "/dashboard/learning-profiles",
    icon: "address-card",
  },
  concerts: {
    text: "Concerts",
    link: "/dashboard/concerts",
    icon: "music",
  },
  comingUpConcerts: {
    text: "Coming Up Concerts",
    link: "/dashboard/upcoming-concerts",
    icon: "drum",
  },
  guestProfile: {
    text: "Profile",
    link: "/dashboard/guest-profile",
    icon: "user",
  },
  guestStatus: {
    text: "Progress",
    link: "/dashboard/guest-status",
    icon: "clock",
  },
  feedbacks: {
    text: "Feedbacks",
    link: "/dashboard/feedbacks",
    Styledicon: FeedbackIcon,
  },
  featureNotifications: {
    text: "Feature notifications",
    link: "/dashboard/feature-notifications",
    Styledicon: BellIcon,
  },
};

const teacherRoutes = [
  routes.TeacherDashboard,
  routes.TeacherStudentList,
  routes.profile,
  routes.teacherAbsences,
  routes.comingUpConcerts,
  routes.teacherLibrary,
  routes.lessonNotes,
  routes.schedule,
  routes.studentsMakeUp,
  routes.teacherAndStaffDirectory,
  routes.myPaymentsPage,
];
const studentRoutes = [
  routes.studentDashboard,
  // routes.home,
  routes.profile,
  routes.comingUpConcerts,
  routes.studentLibrary,
  routes.lessonNotes,
  routes.schedule,
  routes.attendanceList,
  routes.studentAbsences,
  routes.studentQR,
  // routes.checkIn,
];
const parentRoutes = [
  routes.parentDashboard,
  // routes.home,
  routes.profile,
  routes.studentLibrary,
  routes.lessonNotes,
  routes.schedule,
  routes.attendanceList,
  routes.studentAbsences,
  routes.studentQR,
  // routes.checkIn,
];
const superAdminRoutes = [
  routes.superAdminDashboard,
  routes.applications,
  routes.makeUpNeeded,
  routes.profile,
  routes.concerts,
  routes.teacherAbsence,
  routes.privateStudents,
  routes.groupClass,
  routes.learningProfiles,
  routes.makeupRequests,
  routes.trialLessons,
  routes.teachers,
  routes.schedule,
  routes.schoolDates,
  routes.checkIn,
  routes.attendanceList,
  routes.absenceLogs,
  routes.lessonNotes,
  routes.adminLibrary,
  routes.recentPosts,
  routes.signup,
  routes.schoolPolicies,
  routes.adminsPage,
  routes.parentsPage,
  routes.settings,
  routes.studioUsage,
  routes.teacherAndStaffDirectory,
  routes.feedbacks,
  routes.featureNotifications,
  routes.adminsPaymentPage,
];
const adminRoutes = [
  routes.schedule,
  routes.applications,
  routes.privateStudents,
  routes.groupClass,
  routes.learningProfiles,
  routes.makeupRequests,
  routes.concerts,
  routes.makeUpNeeded,
  routes.profile,
  routes.teacherAbsence,
  routes.adminLibrary,
  routes.trialLessons,
  routes.teachers,
  routes.absenceLogs,
  routes.lessonNotes,
  routes.attendanceList,
  routes.checkIn,
  routes.signup,
  routes.adminsPage,
  routes.parentsPage,
  routes.studioUsage,
  routes.teacherAndStaffDirectory,
  routes.myPaymentsPage,
];

const guestRoutes = [routes.guestProfile, routes.guestStatus];

adminRoutes.unshift(routes.dashboard);

const submenusAdminRoutes = [
  routes.privateStudents.link,
  routes.adminsPage.link,
  routes.teachers.link,
  routes.parentsPage.link,
  routes.teacherAndStaffDirectory.link,
  routes.signup.link,
  routes.trialLessons.link,
  routes.groupClass.link,
  routes.learningProfiles.link,
  routes.absenceLogs.link,
  routes.makeUpNeeded.link,
  routes.teacherAbsence.link,
  routes.makeupRequests.link,
  routes.lessonNotes.link,
  routes.adminLibrary.link,
];

const settingsRoutes = {
  emailNotifications: {
    text: "Email Notifications",
    link: "/settings/email-notifications",
    icon: "envelope",
  },
  bellNotifications: {
    text: "Bell Notifications",
    link: "/settings/bell-notifications",
    icon: "bell",
  },
  backToDashboard: {
    text: "Back To Dashboard",
    link: "/dashboard",
    icon: "home",
  },
};

const adminSettingsRoute = [
  settingsRoutes.emailNotifications,
  settingsRoutes.bellNotifications,
  settingsRoutes.backToDashboard,
];
const superAdminSettingsRoute = [
  settingsRoutes.emailNotifications,
  settingsRoutes.bellNotifications,
  settingsRoutes.backToDashboard,
];

export const menuItems = (
  user,
  contentType = sidebarContentTypes.DASHBOARD
) => {
  if (contentType === sidebarContentTypes.SETTINGS) {
    switch (user?.role) {
      case UserRole.SUPER_ADMIN:
        return superAdminSettingsRoute;
      case UserRole.ADMIN:
        return adminSettingsRoute;
      case UserRole.TEACHER:
        return [];
      case UserRole.PARENT:
        return [];
      case UserRole.STUDENT:
        return [];
      default:
        return [];
    }
  } else {
    const isAdminWithPaymentExtraFunctions =
      isAdmin(user?.role) && canManageUsersPayment(user);

    const updatedAdminRoutes = isAdminWithPaymentExtraFunctions
      ? [
          ...adminRoutes.filter(
            (route) => !submenusAdminRoutes.includes(route.link)
          ),
          routes.adminsPaymentPage,
        ]
      : adminRoutes.filter(
          (route) => !submenusAdminRoutes.includes(route.link)
        );

    switch (user?.role) {
      case UserRole.SUPER_ADMIN:
        return superAdminRoutes;
      case UserRole.ADMIN:
        return updatedAdminRoutes;
      case UserRole.TEACHER:
        return teacherRoutes;
      case UserRole.PARENT:
        return parentRoutes;
      case UserRole.STUDENT:
        return studentRoutes;
      case UserRole.GUEST_STUDENT:
      case UserRole.TEACHER_APPLICANT:
      case UserRole.ADMIN_APPLICANT:
      case UserRole.PARTNER_APPLICANT:
        return guestRoutes;
      default:
        return [];
    }
  }
};

const usersMenuItems = [
  {
    key: "users1",
    label: "Private Students",
    icon: <i className={`fas fa-lock`} />,
    link: routes.privateStudents.link,
  },
  {
    key: "users2",
    label: "Admins",
    icon: <i className={`fas fa-user`} />,
    link: routes.adminsPage.link,
  },
  {
    key: "users3",
    label: "Teachers",
    icon: <i className={`fas fa-user`} />,
    link: routes.teachers.link,
  },
  {
    key: "users4",
    label: "Parents",
    icon: <i className={`fas fa-user`} />,
    link: routes.parentsPage.link,
  },
  {
    key: "users5",
    label: "Teacher directory",
    icon: <i className={`fas fa-chalkboard-teacher`} />,
    link: routes.teacherAndStaffDirectory.link,
  },
  {
    key: "users6",
    label: "Sign up users",
    icon: <i className={`fas fa-user`} />,
    link: routes.signup.link,
  },
];

const lessonsMenuItems = [
  {
    key: "lessons1",
    label: "Trial lessons",
    icon: <i className={`fas fa-clock`} />,
    link: routes.trialLessons.link,
  },
  {
    key: "lessons2",
    label: "Group classes",
    icon: <i className={`fas fa-chalkboard-teacher`} />,
    link: routes.groupClass.link,
  },
  {
    key: "lessons3",
    label: "Learning Profiles",
    icon: <i className={`fas fa-sharp fa-regular fa-address-card`} />,
    link: routes.learningProfiles.link,
  },
];

const absencesMenuItems = [
  {
    key: "absences1",
    label: "A/M logs",
    icon: <i className={`fas fa-lock`} />,
    link: routes.absenceLogs.link,
  },
  {
    key: "absences2",
    label: "Make up needed",
    icon: <i className={`fas fa-clock`} />,
    link: routes.makeUpNeeded.link,
  },
  {
    key: "absences3",
    label: "Teacher absence",
    icon: <i className={`fas fa-clock`} />,
    link: routes.teacherAbsence.link,
  },
  {
    key: "absences4",
    label: "Make up requests",
    icon: <i className={`fas fa-users`} />,
    link: routes.makeupRequests.link,
  },
];

const lessonsNotesMenuItems = [
  {
    key: "notes1",
    label: "Lesson notes",
    icon: <i className={`fas fa-user`} />,
    link: routes.lessonNotes.link,
  },
  {
    key: "notes2",
    label: "Admin Library",
    icon: <i className={`fas fa-book-open`} />,
    link: routes.adminLibrary.link,
  },
];

export const adminMenuItems = [
  {
    key: "1",
    icon: <i className={`fas fa-home`} />,
    label: "Dashboard",
    link: routes.dashboard.link,
  },
  {
    key: "2",
    icon: <i className={`fas fa-calendar-alt`} />,
    label: "Schedule",
    link: routes.schedule.link,
  },
  {
    key: "3",
    icon: <i className={`fas fa-users`} />,
    label: "Applications",
    link: routes.applications.link,
  },
  {
    key: "5",
    icon: <i className={`fas fa-user`} />,
    label: "My profile",
    link: routes.profile.link,
  },
  {
    key: "4",
    icon: <i className={`fas fa-music`} />,
    label: "Concerts",
    link: routes.concerts.link,
  },
  {
    key: "6",
    icon: <i className={`fas fa-users`} />,
    label: "Users",
    children: usersMenuItems,
  },
  {
    key: "7",
    icon: <i className={`fas fa-user-check`} />,
    label: "Lessons",
    children: lessonsMenuItems,
  },
  {
    key: "8",
    icon: <i className={`fas fa-clock`} />,
    label: "Absence/Make up",
    children: absencesMenuItems,
  },
  {
    key: "9",
    icon: <i className={`fas fa-pen`} />,
    label: "Lessons notes",
    children: lessonsNotesMenuItems,
  },
  {
    key: "10",
    icon: <i className={`fas fa-check`} />,
    label: "Attendance list",
    link: routes.attendanceList.link,
  },
  {
    key: "11",
    icon: <i className={`fas fa-user-check`} />,
    label: "Check in",
    link: routes.checkIn.link,
  },
  {
    key: "12",
    icon: <i className={`fas fa-music`} />,
    label: "Studio usage",
    link: routes.studioUsage.link,
  },
  {
    key: "13",
    icon: <i className={`fas fa-dollar-sign`} />,
    label: "My payments",
    link: routes.myPaymentsPage.link,
  },
  {
    key: "14",
    icon: <i className={`fas fa-power-off`} />,
    label: "Sign out",
    link: "/",
  },
];

export const superAdminMenuItems = [
  {
    key: "1",
    icon: <i className={`fas fa-home`} />,
    label: "Dashboard",
    link: routes.dashboard.link,
  },
  {
    key: "2",
    icon: <i className={`fas fa-calendar-alt`} />,
    label: "Schedule",
    link: routes.schedule.link,
  },
  {
    key: "19",
    icon: <i className={`fas fa-calendar-alt`} />,
    label: "School dates",
    link: routes.schoolDates.link,
  },
  {
    key: "3",
    icon: <i className={`fas fa-users`} />,
    label: "Applications",
    link: routes.applications.link,
  },
  {
    key: "5",
    icon: <i className={`fas fa-user`} />,
    label: "My profile",
    link: routes.profile.link,
  },
  {
    key: "4",
    icon: <i className={`fas fa-music`} />,
    label: "Concerts",
    link: routes.concerts.link,
  },
  {
    key: "6",
    icon: <i className={`fas fa-users`} />,
    label: "Users",
    children: usersMenuItems,
  },
  {
    key: "7",
    icon: <i className={`fas fa-user-check`} />,
    label: "Lessons",
    children: lessonsMenuItems,
  },
  {
    key: "8",
    icon: <i className={`fas fa-clock`} />,
    label: "Absence/Make up",
    children: absencesMenuItems,
  },
  {
    key: "9",
    icon: <i className={`fas fa-pen`} />,
    label: "Lessons notes",
    children: lessonsNotesMenuItems,
  },
  {
    key: "10",
    icon: <i className={`fas fa-check`} />,
    label: "Attendance list",
    link: routes.attendanceList.link,
  },
  {
    key: "11",
    icon: <i className={`fas fa-user-check`} />,
    label: "Check in",
    link: routes.checkIn.link,
  },
  {
    key: "12",
    icon: <i className={`fas fa-music`} />,
    label: "Studio usage",
    link: routes.studioUsage.link,
  },

  {
    key: "15",
    icon: <i className={`fas fa-address-card`} />,
    label: "Recent posts",
    link: routes.recentPosts.link,
  },
  {
    key: "16",
    icon: <i className={`fas fa-cog`} />,
    label: "Settings",
    link: routes.settings.link,
  },
  {
    key: "21",
    icon: <i className={`fas fa-pen`} />,
    label: "School policies",
    link: routes.schoolPolicies.link,
  },
  {
    key: "18",
    icon: <FeedbackIcon />,
    label: "Feedbacks",
    link: routes.feedbacks.link,
  },
  {
    key: "17",
    icon: <BellIcon />,
    label: "Feature notifications",
    link: routes.featureNotifications.link,
  },
  {
    key: "20",
    icon: <i className={`fas fa-dollar-sign`} />,
    label: "Payment page",
    link: routes.adminsPaymentPage.link,
  },
  {
    key: "14",
    icon: <i className={`fas fa-power-off`} />,
    label: "Sign out",
    link: "/",
  },
];
