import { Field } from "formik";
import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";
import { UploadCloud } from "styled-icons/feather";

export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
export const CheckboxWrapper = styled.span`
  display: inline-block;
  margin-right: 25px;
`;
export const Textarea = styled.textarea`
  display: block;
  min-height: 100px;
  background-color: #bb8a920f;
  outline: none;
  border: 2px solid #ededed;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
`;
export const UploadAttachmentContainer = styled.div`
  min-height: 85px;
  background-color: #bb8a920f;
  outline: none;
  border: 2px solid #ededed;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const UploadPlaceholder = styled.h4`
  color: #dddddd;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0;
  margin-right: 15px;
`;
export const UploadIcon = styled(UploadCloud)`
  color: #dddddd;
`;
export const AttachmentName = styled.span`
  padding: 10px;
  background: #efefef;
  border-radius: 15px;
  margin-right: 5px;
`;

export const AttachmentNameText = styled.span`
  padding: 4px 6px 4px 6px;
  font-size: 12px;
  background: #efefef;
  border-radius: 15px;
  margin-right: 5px;
`;
export const InputWrapper = styled.div`
  width: 20%;
`;
export const CheckBoxField = styled(Field)`
  margin-right: 7px;
`;
