import React, { useState } from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { CustomLabel } from "src/utils/styled";
import { dateFilters } from "../../constants";
import { CalendarPickerWrapper, PrimaryButton } from "src/utils/shared/styled";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import {
  ButtonContainer,
  EllipseIcon,
} from "../../../../common/components/ViewStudentInfoModal/LessonsInfoSection/styled";
import { PrimaryButtonWithLoading } from "../../../../../../utils/shared/shared";
import { ActionBtnFilterWrapper } from "../../styled";

const periodOptions = Object.values(dateFilters).map((filterObj) => {
  return {
    label: filterObj.title,
    value: filterObj.value,
  };
});
const DateFilter = ({
  dateFilter,
  handleDateFilterChange,
  customDateFilterValues,
  handleCustomDateFilterChange,
}) => {
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);

  const selectedFilterObj = dateFilter
    ? Object.values(dateFilters).find(({ value }) => value === dateFilter)
    : null;
  const selectedFilterOption = selectedFilterObj
    ? {
        label: selectedFilterObj.title,
        value: selectedFilterObj.value,
      }
    : null;

  const showCustomRange = dateFilter === dateFilters.CUSTOM.value;

  return (
    <div className="d-flex" style={{ gap: 40 }}>
      <div className="">
        <ActionBtnFilterWrapper>
          <CustomLabel displayBlock>Period:</CustomLabel>

          <div className="ms-4 d-flex">
            <ButtonContainer>
              <PrimaryButtonWithLoading
                backgroundColor="#e357a2"
                onClick={() => handleDateFilterChange(dateFilters.THIS_QUARTER.value)}
                width="145px"
                className="me-2"
                padding="6px"
              >
                This Quarter
              </PrimaryButtonWithLoading>
            </ButtonContainer>
            <ButtonContainer>
              <PrimaryButton
                backgroundColor="#e357a2"
                onClick={() => handleDateFilterChange(dateFilters.NEXT_QUARTER.value)}
                className="me-2"
                width="145px"
                padding="6px"
              >
                Next Quarter
              </PrimaryButton>
            </ButtonContainer>
            <ButtonContainer>
              <PrimaryButton
                backgroundColor="#e357a2"
                onClick={() => handleDateFilterChange(dateFilters.CUSTOM.value)}
                width="145px"
                className="me-2"
                padding="6px"
              >
                Custom Date
              </PrimaryButton>
            </ButtonContainer>
          </div>
        </ActionBtnFilterWrapper>
        <div className="d-flex align-items-center">
          <CustomReactSelect
            isMulti={false}
            placeholder="Period"
            width="320px"
            options={periodOptions}
            onChange={(option) => handleDateFilterChange(option?.value)}
            value={selectedFilterOption}
            wrapOptions={false}
            isClearable={true}
          />
          {showCustomRange && (
            <div className="d-flex ms-4" style={{ gap: 10 }}>
              <div>
                <CustomLabel>Start Date:</CustomLabel>
                <CalendarPickerWrapper>
                  <SingleDatePicker
                    date={customDateFilterValues.startDate}
                    onDateChange={(date) => {
                      handleCustomDateFilterChange("startDate", date);
                    }}
                    focused={isStartDatePickerOpen} // PropTypes.bool
                    onFocusChange={({ focused }) => {
                      setIsStartDatePickerOpen(focused);
                    }}
                    // numberOfMonths={1}
                    // showClearDate={true}
                    isOutsideRange={(date) => {
                      return false;
                    }}
                  />
                </CalendarPickerWrapper>
              </div>
              <div>
                <CustomLabel>End Date:</CustomLabel>
                <CalendarPickerWrapper>
                  <SingleDatePicker
                    date={customDateFilterValues.endDate}
                    onDateChange={(date) => {
                      handleCustomDateFilterChange("endDate", date);
                    }}
                    focused={isEndDatePickerOpen} // PropTypes.bool
                    onFocusChange={({ focused }) => {
                      setIsEndDatePickerOpen(focused);
                    }}
                    // numberOfMonths={1}
                    // showClearDate={true}
                    isOutsideRange={(date) => {
                      if (
                        !customDateFilterValues.startDate ||
                        moment(date).isBefore(
                          customDateFilterValues.startDate,
                          "date"
                        )
                      ) {
                        return true;
                      }
                      return false;
                    }}
                  />
                </CalendarPickerWrapper>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
