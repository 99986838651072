import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import RouteContent from "../../../common/RouteContent";
import "./style.css"
import { UserProfile } from "../../../../utils/styled";
import userImage from "../../../../images/userImage.png";
import { ScreenSize } from "../../../../constants/ScreenSizeEnum";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { MdDelete, MdEdit } from "react-icons/md";
import LearningProfileRecordModal from "./LearningProfileRecordModal";
import { FirebaseContext } from "../../../Firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { downloadSingleItem, injectUserStore } from "../../../../utils/helpers";
import moment from "moment";
import { PROFILE_CATEGORIES } from "../constants/learning-profile";
import { toast } from "react-toastify";
import { AttachmentNameText } from "../../common/components/AddFeedItemModal/styled";
import { Empty } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import ViewstateProfileModal from "./ViewItemProfileModal";
import { TextButton } from "../../../common/styled-components/teacherLibraryStyledComponents";
import DeleteModalRecord from "./DeleteModalRecord";

function LearningProfileDetails() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [learningUser, setLearningProfiles] = useState(null);
  console.log("learningUser", learningUser);
  const [data, setData] = useState([]);
  const firebase = useContext(FirebaseContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [editRecord, setEditRecord] = useState(null);
  const [viewFile, setViewFile] = useState(false);

  const groupedDateByYear = data.reduce((acc, curr) => {
    const year = moment(curr.createdAt).format("YYYY");
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push({ ...curr, year });
    return acc;
  }, {} );
  const sortedData = Object.fromEntries(
    Object.entries(groupedDateByYear).sort(([yearA], [yearB]) => yearB.localeCompare(yearB))
  );
  const sortedDataArray = Object.values(sortedData);

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;
  const [showAddnewRecord, setShowAddnewRecord] = useState(false);
  const [activeFileRecord, setActiveFileRecord] = useState(null);
  const [showDeleteRecord, setShowDeleteRecord] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);

  const getCurrentUser = async () => {
    try {
      const auth = getAuth()
      const user = auth.currentUser;
      setCurrentUser(user);
    } catch (error) {
      console.log(error, "getCurrentUser")
    }
  }

  const getSelectedUser = async () => {
    setIsLoading(true);
    try {
      const learningProfiles = collection(firebase.db, "users");
      const querySnapshot = await getDocs(learningProfiles);

      const profile = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))?.find((profile) => profile?.id === id);

      setLearningProfiles(profile);
    } catch (error) {
      console.log(error, "getSelectedUser")
    } finally {
      setIsLoading(false);
    }
  }

  const getRecords = async () => {
    setIsLoading(true);
    try {
      const learningProfiles = collection(firebase.db, "LearningProfiles");
      const querySnapshot = await getDocs(learningProfiles);

      const profiles = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))?.filter((profile) => profile?.userId === id);

      console.log("profiles", profiles)

      setData(profiles);
    } catch (error) {
      console.log(error, "getRecords")
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  const getCategoryName = (category) => {
    return PROFILE_CATEGORIES.find((cat) => cat?.value === category)?.label
  }

  const deleteRecord = async (id) => {
    try {
      const docRef = doc(firebase.db, "LearningProfiles", id);
      await deleteDoc(docRef);
      toast.success("Record deleted successfully!");
      setShowDeleteRecord(false)
      await getRecords();
    } catch (error) {
      console.error("Error deleting record:", error);
      toast.error("Failed to delete the record.");
    }
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setShowAddnewRecord(true);
  };

  useEffect(() => {
    getCurrentUser();
    getSelectedUser();
    getRecords();
  }, []);

  return (
    <div style={{ position: "relative"}}>
      <RouteContent title="Learning Profile"></RouteContent>

      <div className="achievements-container">
        <div className="d-flex justify-content-between align-items-center mb-3 ms-3">
          <div className="mb-2 ms-3">
            <UserProfile
              width={isDesktopScreen ? "150px" : "90px"}
              height={isDesktopScreen ? "150px" : "90px"}
              className={"rounded-circle"}
              src={learningUser?.imageUrl || userImage}
            />
          </div>
          <div>
            <h3>{learningUser?.fullName || ""}</h3>
          </div>
          <div>
            <button
              className="view-file-btn-main me-2 d-none d-md-block"
              onClick={() => setShowAddnewRecord(true)}
            >
              Add new
            </button>
          </div>
        </div>
        <button
          className="view-file-btn-main me-2 d-block d-md-none align-items-end"
          onClick={() => setShowAddnewRecord(true)}
        >
          Add new
        </button>
        <div className="timeline-container">
          {data.map.length && (
            <div className="timeline">
              {data.map((month) => (
                <div key={month} className="timeline-month">
                  <div className="timeline-dot"></div>
                  <div className="timeline-line"></div>
                </div>
              ))}
            </div>
          )}
          <div className="achievements-list">
            {sortedDataArray.reverse().map((item) => (
              <div key={item.id} className="achievement-card">
                <h3 className="month-title">{item[0]?.year}</h3>
                {item.map((record) => (
                  <div key={record.id} className="month-section">
                    <div className="achievement-card">
                      <div className="achievement-header">
                        <h4>{record.subject}</h4>
                        <span className={`achievement-status reviewed`}>
                        {getCategoryName(record.category)}
                      </span>
                      </div>
                      <div className="achievement-body d-flex justify-content-between flex-wrap">
                        <div className="d-flex flex-column">
                          <div className="d-flex">
                            <p>Teacher: {record.createdBy}</p>

                          </div>
                          <div className="d-flex">
                            <p>
                              Comment: {record.comment}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex flex-column mt-3">
                          {record?.attachments?.length ? record?.attachments.map((attachment) => (
                            <div>
                              <AttachmentNameText
                                style={{ cursor: "pointer", marginBottom: "5px" }}
                                  key={attachment.id}
                                  onClick={() => {
                                    setViewFile(true);
                                    setActiveFileRecord(attachment);
                                  }}>
                                  <PaperClipOutlined />
                                  {attachment?.name}
                                </AttachmentNameText>
                                <TextButton
                                  className={"p-1 text-purple ms-2 me-2"}
                                  onClick={() => {
                                    downloadSingleItem(
                                      attachment.url,
                                      `${
                                        attachment.name
                                      }`,
                                    );
                                  }}
                                >
                                  <i className="fa fa-cloud-download-alt" />
                                </TextButton>
                              </div>

                            ),
                          ) : null}
                        </div>
                      </div>
                      <div className="achievement-actions">
                        <span className="achievement-date">{formatDate(record.createdAt)}</span>

                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            className="view-file-btn me-2"
                            onClick={() => handleEdit(record)}
                          >
                            <MdEdit color="#000" size={18} />
                          </button>
                          <button
                            className="view-file-btn"
                            onClick={() => {
                              setDeleteRecordId(record.id);
                              setShowDeleteRecord(true);
                            }}
                          >
                            <MdDelete color="#000" size={18} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {!sortedDataArray?.length && <Empty />}
          </div>
        </div>
        {showAddnewRecord &&
          <LearningProfileRecordModal
            learningUser={learningUser}
            currentUser={currentUser}
            initialData={editRecord}
            onSaved={() => getRecords()}
            toggleModal={() => setShowAddnewRecord(false)}
            isModalOpen
            closeModal={() => setShowAddnewRecord(false)} />}

        {viewFile && <ViewstateProfileModal state={activeFileRecord} closeModal={() => setViewFile(false)} />}
        {showDeleteRecord && <DeleteModalRecord
          isOpen={showDeleteRecord}
          onConfirm={() => deleteRecord(deleteRecordId) }
          closeModal={() => setShowDeleteRecord(false)}
          toggle={() => setShowDeleteRecord(prevState => !prevState)}
        />}
      </div>
    </div>
  );
}

export default injectUserStore(LearningProfileDetails);
