import { Field } from "formik";
import styled, { css } from "styled-components";
import { Input } from "reactstrap";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

export const CustomFormikFieldInput = styled(Field)`
  display: block;
  height: ${({ height }) => (height ? height : "45px")};
  background-color: ${({ backgroundColor }) => backgroundColor || "#bb8a920f"};
  color: ${({ color }) => color || "#000"};
  outline: none;
  border: 2px solid #ededed;
  border-radius: 15px;
  width: ${({ width }) => (width ? width : "100%")};
  padding-left: 10px;
`;

export const ShowPasswordButton = styled.button`
  position: absolute;
  top: ${({ top }) => (top ? top : "50%")};
  right: 3%;

  border: none;
  outline: none;
  background: transparent;
  width: 24px;
  height: 24px;

  &:hover {
    svg {
      color: #681e46;
    }
  }
`;

export const InputLabel = styled.label`
  display: block;
  margin: 10px 0;
  font-weight: 500;
`;
export const ErrorMessage = styled.p`
  color: #ce0000;
  font-weight: 400;
`;
export const PrimaryButton = styled.button`
  outline: none;
  border: none;
  color: ${({ textColor }) => (textColor ? textColor : "#fff")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#681e46"};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "30px"};
  width: ${({ width }) => (width ? width : "200px")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
  padding: ${({ padding }) => (padding ? padding : "12px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "inherit")};
  position: ${({ position }) => (position ? position : "static")};

  &:hover {
    background-color: ${({ hoverBg }) => (hoverBg ? hoverBg : "#681e46")};
  }
`;

export const PrimaryButtonCustome = styled.button`
  border: none;
  color: ${({ textColor }) => (textColor ? textColor : "#fff")};
  background-color: ${({ backgroundColor }) =>
  backgroundColor ? backgroundColor : "#681e46"};
  border-radius: ${({ borderRadius }) =>
  borderRadius ? borderRadius : "30px"};
  width: ${({ width }) => (width ? width : "200px")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
  padding: ${({ padding }) => (padding ? padding : "12px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "inherit")};
  position: ${({ position }) => (position ? position : "static")};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &:hover {
    background-color: ${({ hoverBg }) => (hoverBg ? hoverBg : "#681e46")};
  }
`;
export const BlinkingButton = styled.button`
  /* @keyframes blink {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  & {
    animation: blink 4s linear infinite;
  }

  @keyframes glowing {
    0% {
      background-color: #b50d67;
      box-shadow: 0 0 3px #b50d67;
    }
    50% {
      background-color: #ff008a;
      box-shadow: 0 0 30px #ff008a;
    }
    100% {
      background-color: #b50d67;
      box-shadow: 0 0 3px #b50d67;
    }
  }
  & {
    animation: glowing 1500ms infinite;
  } */

  background-color: #00ff9c;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  color: #222f3e;
  position: relative;
  height: 1rem;
  width: 1rem;
  animation-name: blink;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: cubic-bezier(0.1, 0.1, 1, 1.8);
  &:before {
    background-color: #00ff9c;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    // z-index:-1;
    animation-name: blink2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: cubic-bezier(0.1, 0.1, 1, 1.8);
  }
  &:after {
    background-color: #00ff9c;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    // z-index:-1
    // animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-timing-function: cubic-bezier(0.1, 0.1, 1, 1.8);
  }

  @keyframes blink2 {
    0% {
      transform: scale3d(1.2, 1.2, 1.2);
      opacity: 0.8;
    }
    100% {
      transform: scale3d(1.8, 1.8, 1.8);
      opacity: 0.1;
    }
  }
  @keyframes blink {
    0% {
      transform: scale3d(0.8, 0.8, 0.8);
      opacity: 0.8;
    }

    100% {
      transform: scale3d(1.6, 1.6, 1.6);
      opacity: 0.1;
    }
  }

  @keyframes blink3 {
    0% {
      transform: scale3d(0.6, 0.6, 0.6);
      opacity: 0.8;
    }
    100% {
      transform: scale3d(1.6, 1.6, 1.6);
      opacity: 0.1;
    }
  }

  /*  */
`;
export const NavButton = styled.button.attrs((props) => {
  return {
    className: props.className,
  };
})`
  font-size: ${({ fontSize }) => fontSize || "20px"};
  font-weight: 600;
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  outline: none;
  box-shadow: none;
  color: #bbbbbb;
  /* padding: 25px; */
  padding-bottom: 10px;
  padding-right: ${({ paddingRight }) => paddingRight || "25px"};
  padding-top: ${({ paddingTop }) => paddingTop || "0px"};

  padding-left: 2px;
  white-space: nowrap;

  &.active {
    color: #681e46;
    position: relative;
  }
  ${({ active }) =>
    active &&
    `
    background: blue;
  `}
  ${({ withRedBar }) =>
    withRedBar &&
    `&.active::before {
    content: "";
    width: 50%;
    height: 3px;
    position: absolute;
    left: 2px;
    bottom: 1px;
    background-color: #681e46;
    border-radius: 10px;
  }`}

  @media (max-width: ${ScreenSize.MD}px) {
    font-size: 15px;
  }
`;

export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "0px")};

  padding: 12px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: ${({ bgColor }) => bgColor || "fff"};
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;

  ${({ hideDisabledOptions }) =>
    hideDisabledOptions &&
    `
    & option[disabled] {
    display: none;
    }
    `}
`;
export const CustomLabel = styled.label`
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
`;
export const CustomInput = styled.input`
  display: ${({ display }) => display || "block"};
  border-radius: 15px;
  padding: ${({ padding }) => (padding ? padding : "10px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => (width ? width : "auto")};

  ::placeholder {
    color: #979797;
  }

  &[type="time"] {
    width: ${({ width }) => (width ? width : "180px")};
  }
  &:disabled {
    background-color: ${({ disabledBgColor }) => disabledBgColor || "#fff"};
  }
`;
export const CurrencyInputContainer = styled.span`
  display: inline-block;
  position: relative;
  &::before {
    content: "$";
    font-family: "Roboto Regular", sans-serif;
    font-size: 1rem;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  & > input {
    padding-left: 17px;
  }
`;
export const CustomFieldInput = styled(Field)`
  display: block;
  border-radius: 15px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => (width ? width : "auto")};

  ::placeholder {
    color: #979797;
  }

  &[type="time"] {
    width: 180px;
  }
`;

export const CustomTextarea = styled.textarea`
  display: block;
  border-radius: 15px;
  margin-top: ${({ marginTop }) => marginTop || "10px"};
  padding: 10px;
  font-weight: 500;
  -webkit-text-fill-color: rgba(0, 0, 0, 1);
  -webkit-opacity: 1;
  color: rgba(0, 0, 0, 1);
  box-shadow: ${({ boxShadow }) => boxShadow || "0px 0px 7px 0px #e3e3e3"};
  border: none;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "75px"};
  background-color: ${({ bgColor }) => bgColor || "auto"};
  resize: ${({ resize }) => resize || "vertical"};
  ::placeholder {
    color: #979797;
  }
  &:disabled {
    background-color: #fff;
  }
`;
export const CalendarPickerWrapper = styled.div`
  & .SingleDatePicker.SingleDatePicker_1 {
    width: 100%;
  }
  &
    .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
    width: 100%;
  }
  & .DateInput.DateInput_1 {
    width: 100%;
  }
  ${({ padding, bgColor }) => css`
    & .DateInput.DateInput_1 .DateInput_input {
      padding: ${padding};
      ${bgColor ? "background-color: " + bgColor : ""}
    }
  `}

  & .DateInput_input__focused {
    border-bottom: 2px solid #681e46;
  }

  & .CalendarDay__selected {
    background: #681e46;
    border: 1px double #681e46;
  }

  & .SingleDatePicker_picker {
    z-index: 5;
  }
`;
export const RequiredInputSpan = styled.span`
  margin-left: 5px;
  color: red;
  font-weight: normal;
`;

export const CustomSelectField2 = styled.select`
  width: 200px;
  padding: 5px;
  border-radius: 15px;
  outline: 0;
  border: 0;
  background: transparent;
  box-shadow: 0px 0px 1px 1px #b2b2b2;
`;

export const CustomTableContainer = styled.div`
  min-height: ${({ minHeight }) => minHeight || "500px"};
  overflow: visible;
`;
export const CustomTable = styled.table`
  width: 100%;
  color: #000;
  & td {
    color: #151515;
    font-weight: 500;
    opacity: 0.9;
  }
  & td,
  & th {
    height: 100%;
    border: 1px solid #ddd;
    padding: 7px;
    text-align: center;
    white-space: nowrap;
  }
  & tr:first-child th {
    border-top: 0;
  }
  & tr:last-child td {
    border-bottom: 0;
  }
  & tr td:first-child,
  & tr th:first-child {
    border-left: 0;
  }
  & tr td:last-child,
  & tr th:last-child {
    border-right: 0;
  }
`;

export const TableRow = styled.tr`
  height: ${({ height }) => height || "60px"};
`;
export const ClickableTableCell = styled.td`
  &:hover {
    color: #ff008a;
    cursor: pointer;
  }
`;

export const SwitchRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ margin }) => margin || "15px 0"};
`;
export const SettingDescription = styled.p`
  margin: 0;
`;
export const CustomSwitch = styled(Input)`
  width: 45px !important;
  height: 20px !important;

  &:checked {
    background-color: #681e46;
    border-color: #681e46;
  }
  &:focus {
    box-shadow: none;
    /* border-color: #681e46; */
  }
`;
export const CustomCheckboxContainer = styled.div`
  input[type="checkbox"] {
    width: 18px;
    height: 16px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    accent-color: #681e46;
  }
`;
export const StyledCopyIcon = styled(Copy)`
  color: #b692a6;
  margin-left: 7px;
  cursor: pointer;
`;

export const ModalButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  color: ${({ primary }) => (primary ? "#681e46" : "grey")};
`;
