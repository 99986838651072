import React, { useContext } from "react";
import { FieldsContainer } from "../styled";

import { PrimaryButton, RequiredInputSpan, StyledCopyIcon } from "src/utils/shared/styled";

import handlePhoneChange from "../../../handlePhoneChange";
import { CustomInput } from "../LessonsInfoSection/styled";
import { useState } from "react";
import { useEffect } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import ChangeAuthInfoModal from "../../ChangeAuthInfoModal";
import useModal from "src/hooks/useModal";
import {
  getAgeFromDate,
  handleCopyText,
  haveSuperAdminPermission,
  injectUserStore,
} from "src/utils/helpers";
import StudentActions from "./StudentActions";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import moment from "moment";
import CustomReactSelect from "../../../../../common/CustomReactSelect";
import { paymentMethodOptions } from "../../AddPrivateStudentModal/constants/paymentMethodOptions";
import { PrivateLessonsContext } from "../../../../PrivateLessons/PrivateLessons";


const paymentOptions = Object.values(paymentMethodOptions).map((filterObj) => {
  return {
    label: filterObj.title,
    value: filterObj.value,
  };
});

const ContactInfoSection = ({ closeModal, currentStudent, UserStore }) => {
  const user = UserStore.user;
  const { updateUserInfo } = useFirebaseFns();
  const authInfoChangeModalData = useModal();
  const [isShowPaymentValidation, setIsShowPaymentValidation] = useState(false);

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const canAccessStudentActions = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.manageStudentActions
  );

  const ageStr = userData.birthday
    ? getAgeFromDate(moment(userData.birthday, "YYYY-MM-DD").toDate())
    : "N/A";

  const {
    refreshList,
  } = useContext(PrivateLessonsContext);

  const saveUserData = async () => {
    const {
      id,
      fullName,
      birthday,
      primaryContactEmail,
      primaryContactName,
      primaryPhone,
      secondaryContactEmail,
      secondaryContactName,
      secondPhone,
      homeAddress,
      city,
      state,
      zip,
      paymentMethod,
    } = userData;
    toast.info("Saving user data...");
    setIsLoading(true);
    await updateUserInfo(id, {
      fullName: fullName ?? "",
      birthday: birthday ?? "",

      primaryContactEmail: primaryContactEmail ?? "",
      primaryContactName: primaryContactName ?? "",
      primaryPhone: primaryPhone ?? "",

      secondaryContactEmail: secondaryContactEmail ?? "",
      secondaryContactName: secondaryContactName ?? "",
      secondPhone: secondPhone ?? "",

      homeAddress: homeAddress ?? "",
      city: city ?? "",
      state: state ?? "",
      zip: zip ?? "",
      paymentMethod: paymentMethod ?? "",
    });
    setIsShowPaymentValidation(false);
    toast.success("Saved.");

    if (refreshList) {
      refreshList();
    }
  };

  useEffect(() => {
    if (currentStudent) setUserData({ ...currentStudent });
  }, []);
  return (
    <div>
      <div className="mb-3 d-flex justify-content-between">
        <h4 className={"fw-bold "}>Student Profile</h4>
        {canAccessStudentActions && (
          <StudentActions
            student={currentStudent}
            refreshData={() => window.location.reload()}
            closeStudentInfoModal={closeModal}
          />
        )}
      </div>
      <FieldsContainer className="mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">
            Name of Student
            <StyledCopyIcon
              onClick={() => handleCopyText(userData.fullName)}
              width={25}
              height={25}
            />
          </p>
          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"name"}
            id="name"
            placeholder="Name"
            value={userData.fullName}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                fullName: e.target.value,
              }));
            }}
          />
        </div>
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">Private Lesson Student ID</p>
          <CustomInput
            type="text"
            disabled={true}
            value={currentStudent?.systemID || "N/A"}
            className="w-100 form-control"
            name={"systemId"}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer className=" mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">Student Birthday</p>
          <CustomInput
            className="w-100 form-control"
            name="birthday"
            placeholder="Birthday"
            type="date"
            value={userData.birthday}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                birthday: e.target.value,
              }));
            }}
          />
        </div>
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">Student Age</p>
          <CustomInput
            className="w-100 form-control"
            name="age"
            placeholder="Age"
            type="text"
            value={ageStr}
            disabled={true}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer>
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">First Enrollment Date</p>
          <CustomInput
            type="date"
            disabled={true}
            className="w-100 form-control"
            name={"EnrollmentDate"}
            value={currentStudent?.enrollmentDate}
          />
        </div>
        <div></div>
      </FieldsContainer>
      {/* #681e46  background #f2ef86*/}
      <FieldsContainer>
        <div className="p-2 w-100 mb-4 mt-4">
          <p className="mb-2 fw-medium">Payment Method</p>
          <CustomReactSelect
            isMulti={false}
            disabledBackgroundColor
            name="paymentMethod"
            placeholder="Payment Method"
            width="300px"
            options={paymentOptions}
            onChange={(option) => {
              setIsShowPaymentValidation(true);
              setUserData((previous) => ({
                ...previous,
                paymentMethod: option?.value
              }));
            }}
            value={paymentOptions.find(
              (option) => option?.value === userData.paymentMethod,
            )}
            wrapOptions={false}
            isClearable={true}
          />
          {isShowPaymentValidation && (
            <span className="errorMsg mt-2">Click "Save" for the update!</span>
          )}
        </div>
        <div></div>
      </FieldsContainer>

      <FieldsContainer className=" mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">
            Primary Email (For Login)
            <PrimaryButton
              onClick={authInfoChangeModalData.openModal}
              className="ms-2"
              padding="6px"
              width="120px"
            >
              Change
            </PrimaryButton>
          </p>
          <CustomInput
            type="email"
            className="w-100 form-control"
            name={"primaryEmail"}
            placeholder="Primary Email"
            value={userData.primaryEmail}
            disabled={true}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer className=" mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">
            Primary Contact Email
            <StyledCopyIcon
              onClick={() => handleCopyText(userData.primaryContactEmail)}
              width={25}
              height={25}
            />
          </p>
          <CustomInput
            type="email"
            className="w-100 form-control"
            name={"primaryContactEmail"}
            placeholder="Primary Contact Email"
            value={userData.primaryContactEmail}
            // disabled={true}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                primaryContactEmail: e.target.value.toLowerCase(),
              }));
            }}
          />
        </div>
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">
            Primary Contact Name
            <StyledCopyIcon
              onClick={() => handleCopyText(userData.primaryContactName)}
              width={25}
              height={25}
            />
          </p>
          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"primaryContactName"}
            placeholder="Primary Contact"
            value={userData.primaryContactName}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                primaryContactName: e.target.value,
              }));
            }}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer>
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">Primary Phone #</p>
          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"primaryPhone"}
            placeholder="Primary Phone"
            value={userData.primaryPhone}
            maxlength="14"
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                primaryPhone: handlePhoneChange(e.target.value),
              }));
            }}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer className=" mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">
            2nd Contact Email
            <StyledCopyIcon
              onClick={() => handleCopyText(userData.secondaryContactEmail)}
              width={25}
              height={25}
            />
          </p>
          <CustomInput
            type="email"
            className="w-100 form-control"
            name={"secondaryContactEmail"}
            placeholder="Secondary Contact Email"
            value={userData.secondaryContactEmail}
            // disabled={true}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                secondaryContactEmail: e.target.value.toLowerCase(),
              }));
            }}
          />
        </div>
        <div className="p-2 w-100">
          <p className="fw-medium mb-2">
            2nd Contact Name
            <StyledCopyIcon
              onClick={() => handleCopyText(userData.secondaryContactName)}
              width={25}
              height={25}
            />
          </p>
          <CustomInput
            className="w-100 form-control"
            type="text"
            name={"secondaryContactName"}
            placeholder="Secondary Contact Name"
            value={userData.secondaryContactName}
            maxlength="14"
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                secondaryContactName: e.target.value,
              }));
            }}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer>
        <div className="p-2 w-100">
          <p className="fw-medium mb-2">2nd Phone #</p>
          <CustomInput
            className="w-100 form-control"
            type="text"
            name={"secondPhone"}
            placeholder="Second Phone"
            value={userData.secondPhone}
            maxlength="14"
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                secondPhone: handlePhoneChange(e.target.value),
              }));
            }}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer className=" mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">Home Address</p>

          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"homeAddress"}
            placeholder="Home Address"
            value={userData.homeAddress}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                homeAddress: e.target.value,
              }));
            }}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer className=" mb-2">
        <div className="p-2">
          <p className="mb-2 fw-medium">City</p>
          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"city"}
            placeholder="City"
            value={userData.city}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                city: e.target.value,
              }));
            }}
          />
        </div>
        <div className="p-2">
          <p className="mb-2 fw-medium">State</p>
          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"state"}
            placeholder="State"
            value={userData.state}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                state: e.target.value,
              }));
            }}
          />
        </div>
        <div className="p-2">
          <p className="mb-2 fw-medium">Zip Code</p>
          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"zip"}
            placeholder="ZIP"
            value={userData.zip}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                zip: e.target.value,
              }));
            }}
          />
        </div>
      </FieldsContainer>
      <div className="px-3 d-flex justify-content-end">
        <button
          onClick={saveUserData}
          disabled={isLoading}
          className="submitbutton h5 px-4 py-1"
        >
          Save
        </button>
      </div>

      {/* Modals */}
      <ChangeAuthInfoModal
        title="Change Login Email"
        modalData={authInfoChangeModalData}
        userId={currentStudent?.id}
        onSuccessCallback={() => window.location.reload()}
      />
    </div>
  );
};

export default injectUserStore(ContactInfoSection);
