/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import "yup-phone";
import { parseNumber } from "libphonenumber-js";
import moment from "moment";
import { updatedMomentNow } from "./helpers";
export const emailValidationSchema = yup
  .string()
  .required("Required")
  .email("Invalid format")
  .max(256, "Maximum ${max} chracters");

export const passwordValidationSchema = yup
  .string()
  .required("Required")
  .matches(/[a-z]/, "at least one lowercase char")
  .matches(/[A-Z]/, "at least one uppercase char")
  .matches(/\d/, "at least one number")
  .min(8, "Minimum ${min} characters")
  .max(256, "Maximum ${max} chracters");

export const passwordConfirmValidationSchema = yup
  .string()
  .oneOf([yup.ref("password"), null], "Passwords don't match")
  .required("Password confirm is required");

export const zipValidationSchema = yup
  .string()
  .required("Required")
  .matches(/^\d{5}([ \-]\d{4})?$/, "Invalid zip code");
export const phoneValidationSchema = yup
  .string()
  .matches(
    /^\(?([0-9]{3})\) ([0-9]{3})[-.●]?([0-9]{4})$/,
    "phone number should be (###) ###-####"
  )
  .min(14, "Minimum ${min} characters")
  .max(14, "Maximum ${max} chracters")
  .required("Required");
// .validateSync(phone)
// try {
//   phoneValidationSchema.validateSync("202-555-0159");
// } catch (err) {
//   // console.log(err.message);
// } // console.log(err.message)  // → this is invalid
/**
   * .test(
      "is-valid-phone-number",
      "Invalid format. Example: 01000875430",
      (value) => (!!value ? !!parseNumber(value).phone : true)
    );
   */
export const requiredStringSchema = yup
  .string()
  .required("Required")
  // eslint-disable-next-line no-template-curly-in-string
  .max(1000, "Maximum ${max} chracters");
export const requiredUserNameStringSchema = yup
  .string()
  .required("Required")
  .matches(/^[a-zA-Z][A-Za-z0-9 ]*$/, "username should start with a letter")
  // eslint-disable-next-line no-template-curly-in-string
  .max(256, "Maximum ${max} chracters");
export const requiredIDStringSchema = yup.string();
// .required("Required")
// .min(6, "Minimum ${min} characters")
// .max(256, "Maximum ${max} chracters");
export const stringMaxValidationSchema = yup
  .string()
  .max(256, "Maximum ${max} chracters");

export const textMaxValidationSchema = yup
  .string()
  .max(1024, "Maximum ${max} chracters");

export const positiveIntegerValidationSchema = yup
  .number()
  .integer("Should be integer")
  .positive("Should be positive number")
  .required("Required")
  .max(Number.MAX_SAFE_INTEGER, "Maximum ${max} integer");

export const positiveNumberValidationSchema = yup
  .number()
  .positive("Should be positive number")
  .required("Required");

export const dateFutureValidationSchema = yup
  .date()
  .min(updatedMomentNow().format("YYYY-MM-DD"), "Should be in future");

export const afterDateValidationSchema = (previous) =>
  yup
    .date()
    .when(previous, (st, schema) =>
      yup.date().min(st, `Should after ${previous}`)
    );
export const dateValidationSchema = yup
  .string()
  .required("Required")
  .test(
    "isCorrectDate",
    "Wrong date",
    (value) => updatedMomentNow().diff(moment(value), "years") <= 90
  );
export const adultValidationSchema = yup
  .string()
  .required("Required")
  .test(
    "isCorrectDate",
    "Wrong date",
    (value) => updatedMomentNow().diff(moment(value), "years") <= 90
  )
  .test(
    "isAdult",
    "Should be adult",
    (value) => updatedMomentNow().diff(moment(value), "years") >= 5
  );
export const requiredNumberSchema = yup.number().required("Required");


export const requiredPaymentOptionSchema = yup
  .string()
  .required("Required");
