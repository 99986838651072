import React from "react";
import "./searchbar.css"

const SearchBar = ({
     onChange,
     value,
  }) => {
  return (
    <div className="InputContainer">
      <input placeholder="Search.."
             value={value}
             onChange={(e) => onChange(e)}
             className="input"
             type="text" />
    </div>
  )
}

export default SearchBar;
