import styled from "styled-components";
import { PeopleFill } from "@styled-icons/bootstrap/PeopleFill";
import { SearchAlt2 } from "styled-icons/boxicons-regular";
import { Info } from "@styled-icons/icomoon/Info";
import { Delete } from "styled-icons/material";

export const Container = styled.div`
  padding: 10px;
`;

export const StatusCircle = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: ${({ bgColor }) => (bgColor ? bgColor : "auto")};
  margin-right: 10px;
  border-radius: 50%;
`;
export const SearchBar = styled.input`
  border-radius: 20px;
  padding: 10px 15px;
  width: 420px;
  border: 2px solid #dddddd;
  outline: none;
  transition: ease-in-out 0.1s;
  cursor: pointer;
  color: #979797;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    border: 2px solid #681e46;
  }
`;
export const SearchIcon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
`;
export const InfoIcon = styled(Info)`
  color: #151515;
  width: 17px;
`;
export const PeopleIcon = styled(PeopleFill)`
  color: #151515;
  width: 19px;
`;
export const ActionBtnsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const IconWrapper = styled.div`
  padding: 5px;
  cursor: pointer;

  &:hover > * {
    color: #ff008a;
  }
`;

export const DeleteIcon = styled(Delete)`
  color: #151515;
  width: 20px;
`;

export const ActionBtnFilterWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
`;
