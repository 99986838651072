import React, { useState } from "react";

import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { ModalBody } from "reactstrap";
import { PrimaryButton } from "src/utils/shared/styled";

import { Divider } from "antd";
import { DeleteButtonWrapper, DeleteStatusWrapper } from "../../Applications/components/DeleteModal/styled";

const DeleteModalRecord = ({ isOpen, toggle, onConfirm, closeModal }) => {
  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>Profile record</StyledModalHeader>
      <ModalBody>
        <DeleteStatusWrapper>
           <p>Do you want to delete the record?</p>
        </DeleteStatusWrapper>
        <Divider />
        <DeleteButtonWrapper>
          <PrimaryButton
            onClick={closeModal}
            className="me-3"
            backgroundColor="#ccc"
            width="max-content"
            padding="12px 30px"
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            backgroundColor="#681e46DD"
            onClick={onConfirm}
            width="max-content"
            padding="12px 30px"
          >
            Confirm
          </PrimaryButton>
        </DeleteButtonWrapper>
      </ModalBody>
    </StyledModal>
  );
};

export default DeleteModalRecord;
