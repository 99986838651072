import moment from "moment";
import React from "react";
import { makeupLimitAbsenceTypes } from "src/constants/absenceTypes";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import {
  getCurrentSchoolYear,
  getPrivateLessonInfoOnSpecificDate,
  isNowDuringLearningYear,
  isRecurringActivityAbsence,
  updatedMomentNow,
} from "src/utils/helpers";
import useFirebaseFns from "./useFirebaseFns";
import { isPaidMakeupTypeMakeupLesson } from "src/constants/makeupLessonsEnum";

const useFirebaseHelpers = () => {
  const {
    getPrivateLessonsByStudentsIds,
    getPrivateLessonsAbsences,
    getStudentMakeupLessons,
    getSchoolYears,
    getPrivateLessonsByIds,
  } = useFirebaseFns();

  const hasStudentReachedMaxMakeUps = async (studentId, lessonId) => {
    if (!studentId || !lessonId) return;

    const [privateLessons, schoolYears, studentMakeups] = await Promise.all([
      getPrivateLessonsByIds([lessonId]),
      getSchoolYears(),
      getStudentMakeupLessons(studentId),
    ]);
    const privateLesson = privateLessons[0];
    if (!privateLesson) return { reachedMaxMakeups: false };

    const isPackageLsn = isPackagePrivateLesson(privateLesson.type);

    let currentSchoolYearInLocation;
    if (!isPackageLsn) {
      const { locationId } = getPrivateLessonInfoOnSpecificDate({
        privateLesson,
        date: updatedMomentNow().toDate(),
        withTimelineApproximation: true,
      });

      currentSchoolYearInLocation = getCurrentSchoolYear(
        schoolYears,
        locationId
      );
      if (!currentSchoolYearInLocation) {
        return { reachedMaxMakeups: false };
      }

      const isDuringLearningYear = isNowDuringLearningYear(
        currentSchoolYearInLocation
      );
      // ? Enable this when we want to allow unlimited makeups outside of the learning year
      // if (!isDuringLearningYear) {
      //   return { reachedMaxMakeups: false };
      // }
    }

    const lessonAbsences = await getPrivateLessonsAbsences([privateLesson.id]);
    // only get absences that will count in calculating the maximum no of makeups (REG,SDC,EA)
    const filteredAbsences = lessonAbsences.filter((absence) =>
      makeupLimitAbsenceTypes.includes(parseInt(absence.absenceType))
    );

    let returnObj = { reachedMaxMakeups: false };
    if (isPackageLsn) {
      loop1: for (const setObj of privateLesson.packageSets) {
        const setItems = setObj.setItems;
        const setItemsAbsences = [];
        loop2: for (const setItem of setItems) {
          const setItemEndDate = moment(setItem.startDate).add(
            setItem.duration,
            "minutes"
          );
          const setItemAbsences = filteredAbsences.filter((absence) =>
            isRecurringActivityAbsence(
              {
                id: privateLesson.id,
                start: setItem.startDate,
                end: setItemEndDate,
              },
              absence,
              { excludeTA: false, excludeGCAbsence: true }
            )
          );
          setItemsAbsences.push(...setItemAbsences);
        }
        // skip this set if no absences
        if (!setItemsAbsences?.length) {
          continue loop1;
        }

        // only getting makeups for the filtered absences and were made in the same learning year
        const filteredAbsencesIds = setItemsAbsences.map(
          (absence) => absence.id
        );
        const filteredMakeupAbsencesIds = studentMakeups
          .filter((lsn) => filteredAbsencesIds.includes(lsn.forAbsenceId))
          .map((lsn) => lsn.forAbsenceId);
        // getting the absenceIds for all makeups then filter out duplicate absenceIds (bec there might be multiple makeup splits for a single absence)
        const uniqueMakeupsAbsencesIds = [
          ...new Set(filteredMakeupAbsencesIds),
        ];
        if (uniqueMakeupsAbsencesIds.length >= 3) {
          returnObj = { reachedMaxMakeups: true };
        }
      }
    } else {
      // only getting makeups for the filtered absences and were made in the same learning year
      const filteredAbsencesIds = filteredAbsences.map((absence) => absence.id);
      const filteredMakeupAbsencesIds = studentMakeups
        .filter(
          (lsn) =>
            filteredAbsencesIds.includes(lsn.forAbsenceId) &&
            !isPaidMakeupTypeMakeupLesson(lsn.type) &&
            moment(lsn.date?.startDate).isBetween(
              currentSchoolYearInLocation.dates.learning_year_start.toDate(),
              currentSchoolYearInLocation.dates.learning_year_end.toDate(),
              undefined,
              "[]"
            )
        )
        .map((lsn) => lsn.forAbsenceId);
      // getting the absenceIds for all makeups then filter out duplicate absenceIds (bec there might be multiple makeup splits for a single absence)
      const uniqueMakeupsAbsencesIds = [...new Set(filteredMakeupAbsencesIds)];

      if (uniqueMakeupsAbsencesIds.length >= 4) {
        returnObj = { reachedMaxMakeups: true };
      }
    }

    return returnObj;
  };

  return { hasStudentReachedMaxMakeUps };
};

export default useFirebaseHelpers;
