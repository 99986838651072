import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ActiveTabContext,
  DeleteNoteAttachmentContext,
  DeleteNoteContext,
  LessonNotesContext,
  NoteAttachmentsContext,
  ShareItemModalContext,
  StudentsContext,
  StudentsSelectContext,
  studentViews,
  TeacherLessonNotesModalContext,
  TeachersContext,
  TeachersSelectContext,
} from "../../common/Library/common/libraryContext";
import LibraryStudentsList from "../../common/Library/tab-content/students-list/LibraryStudentsList";
import {
  calculateFilesSize,
  canUserUploadFileCheck,
  getStudents,
  uploadFileWithRandomId,
} from "../../../utils/helpers";
import { FirebaseContext } from "../../Firebase";
import LessonNotes from "./LessonNotes";
import AddLessonNoteModal from "./AddLessonNoteModal";
import Spinner from "../../common/Spinner";
import { TextButton } from "../../common/styled-components/teacherLibraryStyledComponents";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import DeleteNoteConfirmModal from "./delete-confirm-modal/DeleteNoteConfirmModal";
import DeleteNoteAttachmentConfirmModal from "./delete-confirm-modal/DeleteNoteAttachmentConfirmModal";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
} from "firebase/storage";
import { toast } from "react-toastify";
import AddLessonNoteAttachmentsModal from "./AddLessonNoteAttachmentsModal";
import ShareItemModal from "./share-item-modal/ShareItemModal";
import _ from "lodash";
import "react-calendar/dist/Calendar.css";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import StudentSelectField from "src/components/common/Library/common/mobile/StudentSelectField";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { v4 as uuidv4 } from "uuid";
import { createStorageFileCopy } from "src/utils/apiFns";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import styled from "styled-components";
import { CalendarPickerWrapper, PrimaryButton } from "src/utils/shared/styled";
import { SingleDatePicker } from "react-dates";

const DatePickerContainer = styled.div`
  width: 25%;
  margin-bottom: 20px;

  @media (max-width: ${ScreenSize.XL}px) {
    width: 60%;
    margin-inline: auto;
  }
  @media (max-width: ${ScreenSize.SM}px) {
    width: 100%;
    margin-inline: auto;
  }
`;
const AddLessonNoteBtnContainer = styled.div`
  @media (max-width: ${ScreenSize.XL}px) {
    text-align: center;
  }
`;
const StudentsButtonsContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  width: 25%;
  @media (max-width: ${ScreenSize.XL}px) {
    margin-inline: auto;
    width: 60%;
  }
  @media (max-width: ${ScreenSize.SM}px) {
    margin-inline: auto;
    width: 100%;
  }
`;

const NEW_NOTE = {
  title: "",
  description: "",
  studentsIds: [],
  date: "",
  attachments: [],
};

const TeacherLessonNotes = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const { getFileMetadata } = useFirebaseFns();
  const { width } = useWindowDimensions();
  const isDesktopScreen = width > ScreenSize.XL;

  const [currentStudentsView, setCurrentStudentsView] = useState(
    studentViews.TEACHER_STUDENTS
  );
  const [students, setStudents] = useState([]);
  const [teacherStudents, setTeacherStudents] = useState([]);
  const [date, setDate] = useState("");
  const [teacherNotes, setTeacherNotes] = useState([]);
  const [noteModal, setNoteModal] = useState({
    isOpen: false,
    note: {},
    isEditingMode: false,
  });
  const [deleteAttachmentModal, setDeleteAttachmentModal] = useState({
    isOpen: false,
    attachmentIndex: -1,
    noteId: "",
  });
  const [deleteNoteModal, setDeleteNoteModal] = useState({
    isOpen: false,
    noteId: "",
  });
  const [showShareItemModal, setShowShareItemModal] = useState({
    isOpen: false,
    noteId: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [attachmentsModal, setAttachmentsModal] = useState({
    noteId: "",
    isOpen: false,
  });
  const [teachersList, setTeachersList] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [teacherLessons, setTeacherLessons] = useState([]);
  const [activeTab, setActiveTab] = useState("students");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const storage = getStorage();

  const filteredNotes = useMemo(() => {
    let notes;
    if (!date) {
      notes = teacherNotes;
    } else {
      notes = teacherNotes.filter(
        (note) =>
          moment(note.date.toDate()).format("MM-DD-YYYY") ===
          moment(date.toDate()).format("MM-DD-YYYY")
      );
    }
    if (activeTab === "others") {
      return notes.filter(
        ({ studentsIds, sharedWith }) =>
          studentsIds.length === 0 || sharedWith.indexOf(user.uid) !== -1
      );
    }

    return selectedStudents.length
      ? notes.filter(
          ({ studentsIds }) =>
            _.intersection(selectedStudents, studentsIds).length > 0
        )
      : notes;
  }, [teacherNotes, date, selectedStudents, activeTab]);

  const getStudentsList = async () => {
    const studentsArray = await getStudents(true, false, firebase, user.uid);
    setStudents(studentsArray);
  };
  const getTeacherStudents = async () => {
    const studentsArray = await getStudents(false, true, firebase, user.uid);
    setTeacherStudents(studentsArray);
  };
  const getTeachersList = async () => {
    const teachersDocs = await getDocs(firebase.filterUsersByRole("Teacher"));
    let teachersArray = [];
    teachersDocs.forEach((teacher) => {
      teachersArray.push({
        id: teacher.id,
        ...teacher.data(),
      });
    });
    setTeachersList(teachersArray);
  };
  const onTeacherSelect = (id) => {
    const index = selectedTeachers.findIndex((ID) => id === ID);
    if (index === -1) {
      setSelectedTeachers((prev) => [...prev, id]);
    } else {
      setSelectedTeachers((prev) => prev.filter((ID) => id !== ID));
    }
  };
  const onToggleTeachersClick = () => {
    if (selectedTeachers.length === teachersList.length) {
      setSelectedTeachers([]);
    } else {
      setSelectedTeachers(teachersList.map(({ id }) => id));
    }
  };

  const onCloseShareModal = () => {
    setShowShareItemModal({
      isOpen: false,
      noteId: "",
    });
  };
  const onSubmitShare = async () => {
    toast.info("Sharing item(s)...");
    toast.info("Running in the background, please don't refresh the page.");
    onCloseShareModal();
    await setDoc(
      firebase.getLessonNote(showShareItemModal.noteId),
      {
        sharedWith: selectedTeachers,
      },
      {
        merge: true,
      }
    );
    setTeacherNotes((previous) =>
      previous.map((note) => {
        if (note.id === showShareItemModal.noteId) {
          return {
            ...note,
            sharedWith: [...selectedTeachers],
          };
        }
        return note;
      })
    );
    setSelectedTeachers([]);
    toast.success("Successfully shared.");
  };
  const openShareItemModal = (noteId) => {
    const note = teacherNotes.find(({ id }) => id === noteId);
    console.log(note.sharedWith);
    setSelectedTeachers(note?.sharedWith || []);
    setShowShareItemModal({
      isOpen: true,
      noteId,
    });
  };
  const getTeacherLessonsNotes = async () => {
    const teacherNotesSnap = await getDocs(
      firebase.getTeacherLessonsNotes(user.uid)
    );

    const sharedWithQuery = query(
      firebase.lessonsNotes(),
      where("sharedWith", "array-contains", user.uid)
    );
    const sharedWithSnap = await getDocs(sharedWithQuery);

    const teacherOwnNotes = parseFirebaseDoc(teacherNotesSnap.docs);
    const sharedWithNotes = parseFirebaseDoc(sharedWithSnap.docs);

    const notesArray = [...teacherOwnNotes, ...sharedWithNotes];

    setTeacherNotes(notesArray);
  };
  const getTeacherLessons = async () => {
    const lessons = await getDocs(firebase.getTeacherLessons(user.uid));
    const lessonsArray = [];
    lessons.forEach((lesson) =>
      lessonsArray.push({ id: lesson.id, ...lesson.data() })
    );
    setTeacherLessons(lessonsArray);
  };
  useEffect(async () => {
    setIsLoading(true);
    await Promise.all([
      getStudentsList(),
      getTeacherStudents(),
      getTeacherLessonsNotes(),
      getTeacherLessons(),
      getTeachersList(),
    ]);
    setIsLoading(false);
  }, []);
  const onStudentSelect = (id) => {
    const index = selectedStudents.findIndex((ID) => id === ID);
    if (index === -1) {
      setSelectedStudents((prev) => [...prev, id]);
    } else {
      setSelectedStudents((prev) => prev.filter((ID) => id !== ID));
    }
  };
  const onMultiStudentsSelect = (studentsIds) => {
    setSelectedStudents(studentsIds);
  };

  const onToggleStudentsClick = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map(({ id }) => id));
    }
  };

  const showNoteModal = (note, isEditingMode) => {
    setNoteModal({
      isOpen: true,
      note,
      isEditingMode,
    });
  };
  const createNote = async (newNote) => {
    const added = await addDoc(firebase.lessonsNotes(), newNote);
    newNote.id = added.id;
    setTeacherNotes((previous) => [...previous, newNote]);
  };
  const updateNote = async (noteId, newNote) => {
    await updateDoc(firebase.getLessonNote(noteId), newNote);
    setTeacherNotes((previous) =>
      previous.map((note) => {
        if (note.id === noteId) {
          return {
            ...note,
            ...newNote,
            attachments: [...note.attachments, ...newNote.attachments],
          };
        }
        return note;
      })
    );
  };
  const saveUploadedFileAttachment = async (fileObject) => {
    const { file, attachmentType, recommendationLink } = fileObject;
    const newAttachment = {
      attachmentType,
      isDeleted: false,
    };
    if (attachmentType !== "recommendationLinks") {
      const { downloadUrl, id } = await uploadFileWithRandomId(
        firebase,
        file,
        `lessonNotes/attachments/${user.uid}/`
      );
      if (file.type) {
        newAttachment.type = file.type;
      }
      newAttachment.downloadUrl = downloadUrl;
      newAttachment.fileId = id;
      newAttachment.name = file.name;
    } else {
      newAttachment.downloadUrl = recommendationLink;
      newAttachment.type = "link";
    }
    return newAttachment;
  };
  const saveNewLibraryItem = async (libraryItem) => {
    const { id, attachmentType, downloadUrl, fileId, title, type } =
      libraryItem;
    const newAttachment = {
      attachmentType,
      isDeleted: false,
    };

    if (attachmentType !== "recommendationLinks") {
      const itemRef = ref(storage, downloadUrl);
      const sourcePath = itemRef.fullPath;
      const newFileId = uuidv4();
      const targetPath = `lessonNotes/attachments/${user.uid}/${newFileId}`;

      console.log({ sourcePath, targetPath });
      await createStorageFileCopy(sourcePath, targetPath);
      const copiedFileRef = ref(storage, targetPath);
      const newDownloadUrl = await getDownloadURL(copiedFileRef);

      if (type) {
        newAttachment.type = type;
      }
      newAttachment.downloadUrl = newDownloadUrl;
      newAttachment.fileId = newFileId;
      newAttachment.name = title;
    } else {
      newAttachment.downloadUrl = downloadUrl;
      newAttachment.type = "link";
    }

    return newAttachment;
  };
  const saveAttachments = async (files = [], libraryItems = []) => {
    const attachments = [];
    for (const fileObject of files || []) {
      const newAttachment = await saveUploadedFileAttachment(fileObject);
      attachments.push(newAttachment);
    }
    for (const libraryItem of libraryItems || []) {
      const newAttachment = await saveNewLibraryItem(libraryItem);
      attachments.push(newAttachment);
    }
    console.log({ attachments });
    return attachments;
  };
  const onSubmit = async (formData) => {
    const { isEditingMode } = noteModal;
    setNoteModal({
      isOpen: false,
      note: {},
      isEditingMode: false,
    });

    const { noteId, files, libraryChosenItems, recommendationLink, ...rest } =
      formData;

    const newFiles = files.filter(({ isNew }) => isNew);

    let combinedFilesSizes = 0;

    // gets the uploaded files size if any
    if (newFiles?.length) {
      const filesArr = files
        .filter((file) => file.attachmentType !== "recommendationLinks")
        .map(({ file }) => file);
      const filesSize = calculateFilesSize(filesArr);
      combinedFilesSizes += parseInt(filesSize);
    }

    // gets the chosen library items files size if any
    if (libraryChosenItems?.length) {
      for (const item of libraryChosenItems) {
        if (item.attachmentType !== "recommendationLinks") {
          const metadata = await getFileMetadata(item.downloadUrl);
          const fileSize = metadata?.size || 0;
          combinedFilesSizes += parseInt(fileSize);
        }
      }
    }

    // if there is combined files size, then do the storage capacity check
    if (combinedFilesSizes > 0) {
      const canUpload = canUserUploadFileCheck(user, combinedFilesSizes);
      console.log({ canUpload, combinedFilesSizes });
      if (!canUpload) {
        toast.error("User has reached maximum storage capacity");
        return;
      }
    }

    const newNote = {
      ...rest,
      // date: Timestamp.fromDate(moment(date).toDate()),
      teacherId: user.uid,
      isEdited: isEditingMode,
      updatedAt: Timestamp.now(),
      type: activeTab,
    };

    if (!isEditingMode) {
      newNote.createdBy = user.uid;
      newNote.createdAt = Timestamp.now();
      newNote.sharedWith = [];
      newNote.homework = {};
    }
    const newAttachments = await saveAttachments(newFiles, libraryChosenItems);
    newNote.attachments = [...(newNote.attachments || []), ...newAttachments];
    if (noteId) {
      await updateNote(noteId, newNote);
    } else {
      await createNote(newNote);
    }
    toast.success(
      isEditingMode ? "Updated successfully." : "Created successfully."
    );
  };
  const deleteAttachment = async (attachmentIndex, noteId) => {
    toast.info("Deleting attachment...");
    const note = teacherNotes.find(({ id }) => id === noteId);
    const attachment = note.attachments[attachmentIndex];
    await updateDoc(firebase.getLessonNote(noteId), {
      attachments: arrayRemove(attachment),
    });
    if (attachment.attachmentType !== "recommendationLinks") {
      const itemRef = ref(storage, attachment.downloadUrl);
      await deleteObject(itemRef);
    }
    toast.success("Attachment deleted successfully.");
    note.attachments.splice(attachmentIndex, 1);
    setTeacherNotes((previous) =>
      previous.map((item) => {
        if (item.id === noteId) {
          return {
            ...note,
            attachments: [...note.attachments],
          };
        }
        return item;
      })
    );
  };
  const deleteNote = async (noteId) => {
    toast.info("Deleting note...");
    toast.info("Please don't close the page until finished.");
    const note = teacherNotes.find(({ id }) => id === noteId);
    await deleteDoc(firebase.getLessonNote(noteId));

    const { attachments } = note;
    for (const attachment of attachments) {
      const itemRef = ref(storage, attachment.downloadUrl);
      await deleteObject(itemRef);
    }
    setTeacherNotes((previous) => previous.filter(({ id }) => id !== noteId));
    toast.success("Successfully deleted note.");
  };
  const publishNote = async (noteId) => {
    toast.info("Publishing note...");
    await updateDoc(firebase.getLessonNote(noteId), {
      isDraft: false,
    });
    setTeacherNotes((previous) =>
      previous.map((item) => {
        if (item.id === noteId) {
          return {
            ...item,
            isDraft: false,
          };
        }
        return item;
      })
    );
    toast.success("Note published successfully.");
  };
  const deleteNoteContextValue = {
    isOpen: deleteNoteModal.isOpen,
    close: () => {
      setDeleteNoteModal({
        isOpen: false,
        noteId: "",
      });
    },
    onDelete: async () => {
      const noteId = deleteNoteModal.noteId;
      setDeleteNoteModal({
        isOpen: false,
        noteId: "",
      });
      await deleteNote(noteId);
    },
  };
  const deleteNoteAttachmentContextValue = {
    isOpen: deleteAttachmentModal.isOpen,
    close: () => {
      setDeleteAttachmentModal({
        isOpen: false,
        attachmentId: "",
      });
    },
    onDelete: async () => {
      const attachmentIndex = deleteAttachmentModal.attachmentIndex;
      const noteId = deleteAttachmentModal.noteId;
      setDeleteAttachmentModal({
        isOpen: false,
        attachmentIndex: -1,
        noteId: "",
      });
      await deleteAttachment(attachmentIndex, noteId);
    },
  };

  const onToggleTeachersClickClear = () => {
    setSelectedStudents(['']);
  };


  const studentsSelectContextValue = {
    selected: selectedStudents,
    select: onStudentSelect,
    toggle: onToggleStudentsClick,
    multiSelect: onMultiStudentsSelect,
    clear: onToggleTeachersClickClear,
  };
  const studentsContextValue = {
    students,
    teacherStudents,
    currentStudentsView,
  };
  const teacherLessonNotesModalContextValue = {
    isOpen: noteModal.isOpen,
    note: Object.assign({}, noteModal.note),
    close: () => {
      setNoteModal({
        isOpen: false,
        note: {},
        isEditingMode: false,
      });
    },
    isEditingMode: noteModal.isEditingMode,
  };
  const lessonNotesContextValue = {
    isEditable: true,
    showAttachmentsModal: (noteId) => {
      setAttachmentsModal({
        isOpen: true,
        noteId,
      });
    },
    editNote: (note) => {
      showNoteModal(note, true);
    },
    publishNote,
    deleteNote: (noteId) => {
      setDeleteNoteModal({
        isOpen: true,
        noteId,
      });
    },
    deleteAttachment: (attachmentIndex, noteId) => {
      setDeleteAttachmentModal({
        isOpen: true,
        attachmentIndex,
        noteId,
      });
    },
    notes: filteredNotes,
    students,
    userId: user.uid,
  };
  const noteAttachmentsContextValue = {
    isOpen: attachmentsModal.isOpen,
    close: () => {
      setAttachmentsModal({
        isOpen: false,
        noteId: "",
      });
    },
    onSubmit: async (files) => {
      const { noteId } = attachmentsModal;
      setAttachmentsModal({
        isOpen: false,
        noteId: "",
      });

      const filesArr =
        files
          ?.filter((file) => file.attachmentType !== "recommendationLinks")
          ?.map(({ file }) => file) || [];
      const filesSize = calculateFilesSize(filesArr);
      const canUpload = canUserUploadFileCheck(user, filesSize);
      console.log({ canUpload, filesSize });
      if (!canUpload) {
        toast.error("User has reached maximum storage capacity");
        return;
      }

      const attachments = await saveAttachments(files);
      const note = teacherNotes.find(({ id }) => id === noteId);
      await updateDoc(firebase.getLessonNote(noteId), {
        attachments: [...note.attachments, ...attachments],
      });
      setTeacherNotes((previous) =>
        previous.map((note) => {
          if (note.id === noteId) {
            return {
              ...note,
              attachments: [...note.attachments, ...attachments],
            };
          }
          return note;
        })
      );
      toast.success("Attachments added successfully");
    },
  };

  const teachersSelectContextValue = {
    selected: selectedTeachers,
    select: onTeacherSelect,
    toggle: onToggleTeachersClick,
  };
  const shareItemModalContextValue = {
    isOpen: showShareItemModal.isOpen,
    openShareModal: openShareItemModal,
    closeShareModal: onCloseShareModal,
    submitShare: onSubmitShare,
  };

  const teachersContextValue = {
    teachers: teachersList,
  };

  const activeTabContextValue = {
    activeTab,
    setActiveTab,
    isTeacher: true,
    isAdmin: false,
  };
  return (
    <div className={"h-100"}>
      {isLoading ? (
        <Spinner />
      ) : (
        <StudentsContext.Provider value={studentsContextValue}>
          <TeachersContext.Provider value={teachersContextValue}>
            <StudentsSelectContext.Provider value={studentsSelectContextValue}>
              <ActiveTabContext.Provider value={activeTabContextValue}>
                <TeachersSelectContext.Provider
                  value={teachersSelectContextValue}
                >
                  {/* <div className="d-flex w-100 mb-3 align-items-start">
                    <SingleTab
                      isActive={activeTab === 'students'}
                      className="btn"
                      onClick={() => setActiveTab('students')}>
                      My Students
                    </SingleTab>
                    <SingleTab
                      isActive={activeTab === 'others'}
                      className="btn ms-5"
                      onClick={() => setActiveTab('others')}>
                      Others
                    </SingleTab>
                  </div> */}
                  <AddLessonNoteBtnContainer>
                    <TextButton
                      onClick={() => {
                        showNoteModal(
                          { ...NEW_NOTE, date, studentsIds: selectedStudents },
                          false
                        );
                      }}
                    >
                      <i className="fa fa-plus me-2" />
                      Add New Note
                    </TextButton>
                  </AddLessonNoteBtnContainer>
                  <DatePickerContainer>
                    <CalendarPickerWrapper className="w-100 mt-3">
                      <SingleDatePicker
                        date={date} // momentPropTypes.momentObj or null
                        onDateChange={setDate}
                        focused={isDatePickerOpen}
                        onFocusChange={({ focused }) => {
                          setIsDatePickerOpen(focused);
                        }}
                        // numberOfMonths={1}
                        // showClearDate={true}
                        isOutsideRange={(date) => {
                          return false;
                        }}
                      />
                    </CalendarPickerWrapper>
                  </DatePickerContainer>
                  <StudentsButtonsContainer>
                    <PrimaryButton
                      onClick={() =>
                        setCurrentStudentsView(studentViews.TEACHER_STUDENTS)
                      }
                      backgroundColor={
                        currentStudentsView === studentViews.TEACHER_STUDENTS
                          ? "#681e46"
                          : "#d71d82"
                      }
                      width="50%"
                      padding="9px"
                    >
                      Show My Students
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => setCurrentStudentsView(studentViews.ALL)}
                      backgroundColor={
                        currentStudentsView === studentViews.ALL
                          ? "#681e46"
                          : "#d71d82"
                      }
                      width="50%"
                      padding="9px"
                    >
                      Show All Students
                    </PrimaryButton>
                  </StudentsButtonsContainer>
                  {!isDesktopScreen ? (
                    <div className="mb-4">
                      <StudentSelectField />
                    </div>
                  ) : null}
                  <div className={"d-flex justify-content-between"}>
                    {/* Students list */}
                    {isDesktopScreen ? (
                      <LibraryStudentsList withToggle={false} />
                    ) : null}
                    <TeacherLessonNotesModalContext.Provider
                      value={teacherLessonNotesModalContextValue}
                    >
                      {noteModal.isOpen ? (
                        <AddLessonNoteModal onSubmit={onSubmit} />
                      ) : null}
                    </TeacherLessonNotesModalContext.Provider>
                    <DeleteNoteContext.Provider value={deleteNoteContextValue}>
                      <DeleteNoteConfirmModal userId={user.uid} />
                    </DeleteNoteContext.Provider>
                    <DeleteNoteAttachmentContext.Provider
                      value={deleteNoteAttachmentContextValue}
                    >
                      <DeleteNoteAttachmentConfirmModal userId={user.uid} />
                    </DeleteNoteAttachmentContext.Provider>
                    <NoteAttachmentsContext.Provider
                      value={noteAttachmentsContextValue}
                    >
                      {attachmentsModal.isOpen ? (
                        <AddLessonNoteAttachmentsModal />
                      ) : null}
                    </NoteAttachmentsContext.Provider>
                    <ShareItemModalContext.Provider
                      value={shareItemModalContextValue}
                    >
                      <ShareItemModal />
                      <LessonNotesContext.Provider
                        value={lessonNotesContextValue}
                      >
                        <div className={"flex-grow-1"} style={{ width: "75%" }}>
                          <LessonNotes />
                        </div>
                      </LessonNotesContext.Provider>
                    </ShareItemModalContext.Provider>
                  </div>
                </TeachersSelectContext.Provider>
              </ActiveTabContext.Provider>
            </StudentsSelectContext.Provider>
          </TeachersContext.Provider>
        </StudentsContext.Provider>
      )}
    </div>
  );
};
export default TeacherLessonNotes;
