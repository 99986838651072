/* eslint-disable default-case */
import React, { useContext } from "react";
import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalHeader,
} from "../../../common/styled-components/teacherLibraryStyledComponents";
import ImageViewer from "../../../common/Library/view-item-modal/ImageViewer";
import PDFViewer from "../../../common/Library/view-item-modal/PDFViewer";
import AudioViewer from "../../../common/Library/view-item-modal/AudioViewer";
import VideoViewer from "../../../common/Library/view-item-modal/VideoViewer";
import LinkViewer from "../../../common/Library/view-item-modal/LinkViewer";



const ViewstateProfileModal = ({ closeModal, state}) => {

  const renderContent = () => {
    console.log(state)
    if (state?.type && state?.url) {
      if (state.type.includes("image")) {
        return <ImageViewer image={state.url} title={state.name} />;
      }
      if (state.type.includes("video")) {
        return <VideoViewer video={state.url} title={state.name} showClose={false} />;
      }
      if (state.type.includes("pdf")) {
        return <PDFViewer file={state.url} title={state.name}/>;
      }
      switch (state.type) {
        case "musicSheets":
          if (state.type.includes("image")) {
            return <ImageViewer image={state.url} title={state.name} />;
          } else if (state.type.includes("pdf")) {
            return <PDFViewer file={state.url} title={state.name}/>;
          } else {
            return null;
          }
        case 'recordings':
          return <AudioViewer audio={state.url} title={state.name} />;
        case "videos":
          return <VideoViewer video={state.url} title={state.name} />;
        case "recommendationLinks":
          return <LinkViewer link={state.url} title={state.name} />;
      }
    }
    return null;
  };
  return (
    <LibraryModal
      className={"p-0"}
      centered
      isOpen
      size={'lg'}
    >
      {state?.type !== "recordings" && state?.type !== "videos" && (
        <LibraryModalHeader
          close={
            <button className="close" onClick={closeModal}>
              <i className="fas fa-times" />
            </button>
          }
        />
      )}
      <LibraryModalBody className={"p-0"}>
        {renderContent()}
      </LibraryModalBody>
    </LibraryModal>
  );
};
export default ViewstateProfileModal;
