import moment from "moment";
import { paymentDatesFormat } from "src/constants/paymentsEnum";
import { updatedMomentNow } from "src/utils/helpers";

export function getPaymentDateRanges() {
  const FIRST_DATE_START_DAY = 13;
  const FIRST_DATE_END_DAY = 27;
  const SECOND_DATE_START_DAY = 28;
  const SECOND_DATE_END_DAY = 12;

  // start from the start of prev year and ends on the end of the next year
  const startDate = updatedMomentNow().startOf("year");
  const endDate = updatedMomentNow().add(1, "year").endOf("year");

  // array of years from the startDate to the endDate
  const years = [];
  let currentYear = startDate.year();
  while (currentYear <= endDate.year() && currentYear < 2200) {
    years.push(currentYear);
    currentYear++;
  }

  // contains the year and month for the dates between the startDate and endDate
  const yearAndMonthCombinations = [];
  years.forEach((year) => {
    const yearMonths = Array.from(Array(12).keys());
    yearMonths.forEach((month) => {
      yearAndMonthCombinations.push({
        year,
        month: month,
      });
    });
  });

  // setting the first element of the yearAndMonthCombinations array to include the period before the current year
  const firstCombination = yearAndMonthCombinations[0];
  const firstStart = updatedMomentNow()
    .set({
      year: firstCombination.year - 1,
      month: 11,
      date: SECOND_DATE_START_DAY,
    })
    .format(paymentDatesFormat);
  const firstEnd = moment(firstStart)
    .add(1, "month")
    .set({
      date: SECOND_DATE_END_DAY,
    })
    .format(paymentDatesFormat);

  const lastRangeOfPrevYear = {
    start: firstStart,
    end: firstEnd,
  };
  // dateRanges array will start with the last range of the previous year
  const dateRanges = [lastRangeOfPrevYear];
  yearAndMonthCombinations.forEach((combination) => {
    const { year, month } = combination;

    const firstStartDate = updatedMomentNow()
      .set({
        year,
        month,
        date: FIRST_DATE_START_DAY,
      })
      .format(paymentDatesFormat);
    const firstEndDate = moment(firstStartDate)
      .set({ date: FIRST_DATE_END_DAY })
      .format(paymentDatesFormat);
    const secondStartDate = updatedMomentNow()
      .set({
        year,
        month,
        date: SECOND_DATE_START_DAY,
      })
      .format(paymentDatesFormat);
    const secondEndDate = moment(secondStartDate)
      .add(1, "month")
      .set({
        date: SECOND_DATE_END_DAY,
      })
      .format(paymentDatesFormat);

    dateRanges.push(
      {
        start: firstStartDate,
        end: firstEndDate,
      },
      { start: secondStartDate, end: secondEndDate }
    );
  });

  return dateRanges;
}
