import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import SearchSelect from "src/components/common/Library/common/SearchSelect";
import "./style.css";
import { Modal } from "../../common";
import {
  AttendanceContext,
  AttendanceModalContext,
} from "../../common/contexts/AdminAttendanceContext";
import { TimePicker } from "antd";
import dayjs from "dayjs";

const MarkAttendanceModal = () => {
  const { close, isOpen, attendance, submit, isEdit, deleteAttendance } =
    useContext(AttendanceModalContext);
  const { locations } = useContext(AttendanceContext);

  const [attendanceDate, setAttendanceDate] = useState(
    moment(
      isEdit
        ? moment(attendance.attendanceDate, "MM-DD-YYYY hh:mmA")
        : attendance.lessonDate
    ).format("YYYY-MM-DD")
  );
  const [attendanceTime, setAttendanceTime] = useState(
    moment(
      isEdit
        ? moment(attendance.attendanceDate, "MM-DD-YYYY hh:mmA")
        : attendance.lessonDate
    ).format("HH:mm")
  );

  // const [attendanceType, setAttendanceType] = useState(attendance.type);
  const [selectedLocation, setSelectedLocation] = useState({
    label: "",
    value: "",
  });
  useEffect(() => {
    if (locationsOptions.length) {
      const locationOption = locationsOptions.find(
        ({ value }) => value === attendance.locationId
      );
      setSelectedLocation(locationOption);
    }
  }, [attendance, locationsOptions]);
  const locationsOptions = useMemo(() => {
    return locations.map(({ id, name }) => ({ label: name, value: id }));
  }, [locations]);
  const [showRequiredMsg, setShowRequiredMsg] = useState(false);

  const onSubmitHandler = () => {
    if (selectedLocation) {
      setShowRequiredMsg(false)
      submit({
        ...attendance,
        attendanceDate: moment(`${attendanceDate} ${attendanceTime}`),
        locationId: selectedLocation.value,
      })
    } else {
      setShowRequiredMsg(true)
    }
  }

  const buttons = (
    <>
      <Button
        onClick={onSubmitHandler}
        className="bg-purple"
      >
        Mark
      </Button>
      <Button onClick={deleteAttendance} color="danger" className="ms-2">
        Clear
      </Button>
    </>
  );

  const handleTimeChange = (time) => {
    if (time) {
      setAttendanceTime(time.format("HH:mm"));
    } else {
      setAttendanceTime("");
    }
  };

  const modalBody = () => (
    <div
      className={
        "d-flex flex-column py-5 align-items-center justify-content-center"
      }
    >
      {/* <p className="lead mb-2">Lesson Date: {attendance.lessonDate.format("MM-DD-YYYY hh:mmA")}</p> */}
      <div className="px-5 col w-100">
        <div className="d-flex col mb-2 align-items-center">
          <p className="mb-0 lead">Attendance Date: </p>
          <div className="inputContainer ms-2 flex-grow-1">
            <input
              className="InputField w-100 m-0"
              type="date"
              value={attendanceDate}
              onChange={(e) => {
                setAttendanceDate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex col mb-2 align-items-center">
          <p className="mb-0 lead">Attendance Time: </p>
          <div className="inputContainer ms-2 flex-grow-1">
            <TimePicker
              className="InputField w-100 m-0"
              value={dayjs(attendanceTime, "HH:mm")}
              use12Hours format="h:mm a"
              onChange={handleTimeChange}
            />
          </div>
        </div>
        <div className="mb-2 d-flex align-items-center">
          <p className="mb-0 lead me-2">Check-In Location: </p>
          <div className="d-flex flex-column">
            <SearchSelect
              name="locations"
              placeholder={"Location"}
              options={locationsOptions}
              onOptionSelect={(e) => {
                setSelectedLocation(e);
              }}
              value={selectedLocation}
              isMulti={false}
            />
            {showRequiredMsg && (
              <span className="errorMsg notification-msg">Location is required</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Mark Attendance"}
      isOpen={isOpen}
      toggle={close}
      buttons={buttons}
    />
  );
};
export default MarkAttendanceModal;
