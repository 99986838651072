import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  UserProfile,
} from "src/utils/styled";
import * as yup from "yup";
import {
  emailValidationSchema,
  phoneValidationSchema,
  requiredStringSchema,
  zipValidationSchema,
  dateValidationSchema, requiredPaymentOptionSchema,
} from "src/utils/validations";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";
import { RequiredInputSpan } from "src/utils/shared/styled";
import styled from "styled-components";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import handlePhoneChange from "src/components/Dashboard/common/handlePhoneChange";
import { toast } from "react-toastify";
import { setDoc, Timestamp } from "firebase/firestore";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { UserRole } from "src/constants/UserRoleEnum";
import useDraftStudent from "../hooks/useDraftStudent";
import { SubmitSpinner } from "src/components/common";
import { getInitialsImageUrl, updatedMomentNow } from "src/utils/helpers";
import CustomReactSelect from "../../../../../common/CustomReactSelect";
import { paymentMethodOptions } from "../constants/paymentMethodOptions";

const Closeicon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const Righticon = styled(CaretRightFill)`
  color: #fff;
  width: 20px;
  position: absolute;
  top: 10%;
  right: 15px;
`;



const paymentOptions = Object.values(paymentMethodOptions).map((filterObj) => {
  return {
    label: filterObj.title,
    value: filterObj.value,
  };
});

const SecondaddStudentFormValuesValidationSchema = yup.object().shape({
  primaryContactEmail: emailValidationSchema,
  primaryContactName: requiredStringSchema,
  primaryEmail: emailValidationSchema,
  gender: requiredStringSchema,
  enrollmentDate: dateValidationSchema,
  birthday: dateValidationSchema,
  paymentMethod: requiredPaymentOptionSchema,
  primaryPhone: phoneValidationSchema,
  homeAddress: requiredStringSchema,
  city: requiredStringSchema,
  zip: zipValidationSchema,
  state: requiredStringSchema,
});

const SecondStageModal = ({
  studentInfo,
  setStudentInfo,
  uploadRef,
  secondStageInitialFormData,
  secondStageModalData,
  setUploading,
  uploading,
  onAddStudentSuccess,
  setcurrentUserID,
  createPLModalData,
  resetStudentInfo,
}) => {
  const firebase = useContext(FirebaseContext);
  const { secondaryApp } = firebase;
  const { saveStudentAsDraft, isSubmitting: isSubmittingDraftStudent } =
    useDraftStudent();

  const handleFormValueChange = (e, setFieldValue, isPhoneField) => {
    const value = isPhoneField
      ? handlePhoneChange(e.target.value)
      : [
          "primaryContactEmail",
          "secondaryContactEmail",
          "primaryEmail",
        ].includes(e.target.name)
      ? e.target.value.toLowerCase()
      : e.target.value;

    setFieldValue(e.target.name, value);
    setStudentInfo((oldVal) => ({
      ...oldVal,
      [e.target.name]: value,
    }));
  };

  const onFormSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const auth = getAuth(secondaryApp);
      const lowerCaseEmail = studentInfo.primaryEmail.toLowerCase();
      const authUser = await createUserWithEmailAndPassword(
        auth,
        lowerCaseEmail,
        process.env.REACT_APP_USERS_GLOBAL_PASSWORD
      );
      if (!authUser.user.uid) return;

      const studentObj = {
        ...studentInfo,
        createdAt: Timestamp.now(),
        role: UserRole.STUDENT,
        active: true,
        primaryEmail: lowerCaseEmail,
      };
      await setDoc(firebase.user(authUser.user.uid), studentObj).then(() => {
        const userID = auth.currentUser.uid;
        setcurrentUserID(userID);
      });
      toast.success("Student Created Successfully");
      onAddStudentSuccess(studentObj);
      secondStageModalData.closeModal();
      createPLModalData.openModal();
      resetStudentInfo();

      var x = document.getElementsByTagName("BODY")[0];
      x.classList.remove("overflowBody");
    } catch (error) {
      toast.error(error.message);
      console.log("saving student-error", error);
    } finally {
      setSubmitting(false);
    }
  };

  const onSecondStageModalClose = () => {
    secondStageModalData.closeModal();
    resetStudentInfo();
  };

  useEffect(() => {
    const initialAvatarImage = getInitialsImageUrl(studentInfo.fullName);
    setStudentInfo((oldVal) => ({
      ...oldVal,
      imageUrl: initialAvatarImage,
    }));
  }, []);
  console.log(studentInfo, "studentINfo")

  return (
    <Formik
      initialValues={secondStageInitialFormData}
      validationSchema={SecondaddStudentFormValuesValidationSchema}
      onSubmit={onFormSubmit}
    >
      {({
          touched,
          errors,
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
    <CustomModal centered isOpen={secondStageModalData.isModalOpen} size={"lg"}>
      <CustomModalHeader
        close={<Closeicon onClick={onSecondStageModalClose} />}
      >
        <div className="headerContainer">
          <div className={"me-3"}>
            <UserProfile
              width={"120px"}
              height={"120px"}
              className="rounded-circle d-flex imageContainer"
              src={studentInfo.imageUrl}
            >
              <input
                ref={uploadRef}
                hidden
                type={"file"}
                onChange={(e) => {
                  const file = e.target.files[0];
                  const filePath = `profileImages/${studentInfo.systemID}`;
                  const fileRef = ref(firebase.storage, filePath);
                  const uploadTask = uploadBytesResumable(fileRef, file);
                  uploadTask.on(
                    "state_changed",
                    () => {
                      setUploading(true);
                    },
                    () => {
                      setUploading(false);
                    },
                    () => {
                      getDownloadURL(fileRef).then((url) => {
                        setUploading(false);
                        setStudentInfo((oldVal) => ({
                          ...oldVal,
                          imageUrl: url,
                        }));
                      });
                    }
                  );
                }}
              />
              {/* {uploading || !studentInfo.imageUrl ? (
                <div className="m-auto p-1 imageContainer">
                  <img src={userImage} className="userImage" />
                </div>
              ) : null} */}
            </UserProfile>
          </div>
          <div>
            <h3>{studentInfo.fullName}</h3>
            <p>Student ID : {studentInfo.systemID}</p>
          </div>
          <div>
            <img
              className="w-100 h-100"
              src={studentInfo.qrCodeImageUrl}
              alt={studentInfo.systemID}
            />
          </div>
        </div>
      </CustomModalHeader>
      <div className="buttonContainer buttonContainerAlign d-flex align-items-center justify-content-between">
        <button
          className="mb-3"
          onClick={() => {
            uploadRef.current.click();
          }}
        >
          Upload Image
        </button>
        <div className="InputGroup d-flex justify-content-end">
          <div className="inputContainer">
            <label htmlFor="paymentMethod">
              Payment Method <RequiredInputSpan>*</RequiredInputSpan>
            </label>
            <CustomReactSelect
              isMulti={false}
              name="paymentMethod"
              placeholder="Payment Method"
              width="300px"
              options={paymentOptions}
              onChange={(option) => {
                console.log("option", option)
                setFieldValue("paymentMethod", option?.value);
                setStudentInfo((oldVal) => ({
                  ...oldVal,
                  paymentMethod: option?.value,
                }))
              }}
              value={paymentOptions.find(
                (option) => option?.value === values?.paymentMethod,
              )}
              wrapOptions={false}
              isClearable={true}
            />
            {errors.paymentMethod && (
              <span className="errorMsg">{errors.paymentMethod}</span>
            )}
          </div>
        </div>
      </div>

      <CustomModalBody className="">

            <Form>
              <div>
                <div className="InputGroup">
                  <div className="inputContainer">
                    <label htmlFor="birthday">
                      Birthday <RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Birthday"}
                      name="birthday"
                      placeholder="Birthday"
                      type="date"
                      error={errors.birthday}
                      value={values.birthday}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      noValidate
                    />
                    {touched.birthday && errors.birthday && (
                      <span className="errorMsg">{errors.birthday}</span>
                    )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="Gender">
                      Gender <RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Gender"}
                      name={"gender"}
                      placeholder="Gender"
                      error={errors.gender}
                      value={values.gender}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      maxLength="10"
                    />
                    {touched.gender && errors.gender && (
                      <span className="errorMsg">{errors.gender}</span>
                    )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="Enrollment Date">
                      Enrollment Date <RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Enrollment Date"}
                      name={"enrollmentDate"}
                      type="date"
                      max={updatedMomentNow()
                        .add(10, "years")
                        .format("YYYY-MM-DD")}
                      min={updatedMomentNow()
                        .subtract(10, "years")
                        .format("YYYY-MM-DD")}
                      placeholder="Enrollment Date"
                      error={errors.enrollmentDate}
                      value={values.enrollmentDate}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      noValidate
                    />
                    {touched.enrollmentDate && errors.enrollmentDate && (
                      <span className="errorMsg">{errors.enrollmentDate}</span>
                    )}
                  </div>
                </div>
                <div className="InputGroup">
                  <div className="inputContainer">
                    <label htmlFor="primaryEmail">
                      Primary Email (For Login)
                      <RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Primary Email"}
                      name={"primaryEmail"}
                      placeholder="Primary Email"
                      error={errors.primaryEmail}
                      value={values.primaryEmail}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      maxLength="50"
                    />
                    {touched.primaryEmail && errors.primaryEmail && (
                      <span className="errorMsg">{errors.primaryEmail}</span>
                    )}
                  </div>
                </div>
                <div className="InputGroup">
                  <div className="inputContainer">
                    <label htmlFor="primaryContactEmail">
                      Primary Contact Email
                      <RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Primary Contact Email"}
                      name={"primaryContactEmail"}
                      placeholder="Primary Contact Email"
                      error={errors.primaryContactEmail}
                      value={values.primaryContactEmail}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      maxLength="50"
                    />
                    {touched.primaryContactEmail &&
                      errors.primaryContactEmail && (
                        <span className="errorMsg">
                          {errors.primaryContactEmail}
                        </span>
                      )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="primaryContactEmail">
                      Primary Contact Name
                      <RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Primary Contact Name"}
                      name={"primaryContactName"}
                      placeholder="Primary Contact Name"
                      error={errors.primaryContactName}
                      value={values.primaryContactName}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      maxLength="50"
                    />
                    {touched.primaryContactName &&
                      errors.primaryContactName && (
                        <span className="errorMsg">
                          {errors.primaryContactName}
                        </span>
                      )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="primaryPhone">
                      primary Phone<RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Primary Phone"}
                      name={"primaryPhone"}
                      placeholder="Primary Phone"
                      error={errors.primaryPhone}
                      value={values.primaryPhone}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue, true);
                      }}
                      onBlur={handleBlur}
                      maxlength="14"
                    />
                    {touched.primaryPhone && errors.primaryPhone && (
                      <span className="errorMsg">{errors.primaryPhone}</span>
                    )}
                  </div>
                </div>
                <div className="InputGroup">
                  <div className="inputContainer">
                    <label htmlFor="secondaryContactEmail">
                      2nd Contact Email
                    </label>
                    <input
                      className="InputField"
                      label={"secondaryContactEmail"}
                      name={"secondaryContactEmail"}
                      placeholder="Secondary Contact Email"
                      error={errors.secondaryContactEmail}
                      value={values.secondaryContactEmail}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      maxLength="50"
                    />
                    {touched.secondaryContactEmail &&
                      errors.secondaryContactEmail && (
                        <span className="errorMsg">
                          {errors.secondaryContactEmail}
                        </span>
                      )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="secondaryContactName">
                      2nd Contact Name
                    </label>
                    <input
                      className="InputField"
                      label={"secondaryContactName"}
                      name={"secondaryContactName"}
                      placeholder="Secondary Contact Name"
                      error={errors.secondaryContactName}
                      value={values.secondaryContactName}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      maxLength="40"
                    />
                    {touched.secondaryContactName &&
                      errors.secondaryContactName && (
                        <span className="errorMsg">
                          {errors.secondaryContactName}
                        </span>
                      )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="secondPhone">2nd Phone</label>
                    <input
                      className="InputField"
                      label={"2nd Phone"}
                      name={"secondPhone"}
                      placeholder="2nd Phone"
                      error={errors.secondPhone}
                      value={values.secondPhone}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue, true);
                      }}
                      noValidate
                      maxlength="14"
                    />
                    {touched.secondPhone && errors.secondPhone && (
                      <span className="errorMsg">{errors.secondPhone}</span>
                    )}
                  </div>
                </div>
                <div className="InputGroup">
                  <div className="inputContainer w-100">
                    <label htmlFor="homeAddress">
                      home Address<RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField w-100"
                      label={"Home Address"}
                      name={"homeAddress"}
                      placeholder="Home Address"
                      error={errors.homeAddress}
                      value={values.homeAddress}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.homeAddress && errors.homeAddress && (
                      <span className="errorMsg">{errors.homeAddress}</span>
                    )}
                  </div>
                </div>
                <div className="InputGroup">
                  <div className="inputContainer">
                    <label htmlFor="city">
                      city<RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"City"}
                      name={"city"}
                      placeholder="City"
                      error={errors.city}
                      value={values.city}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.city && errors.city && (
                      <span className="errorMsg">{errors.city}</span>
                    )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="state">
                      State<RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"Statue"}
                      name={"state"}
                      placeholder="State"
                      error={errors.state}
                      value={values.state}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.state && errors.state && (
                      <span className="errorMsg">{errors.state}</span>
                    )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="zip">
                      zip Code<RequiredInputSpan>*</RequiredInputSpan>
                    </label>
                    <input
                      className="InputField"
                      label={"ZIP"}
                      name={"zip"}
                      placeholder="ZIP"
                      error={errors.zip}
                      value={values.zip}
                      onChange={(e) => {
                        handleFormValueChange(e, setFieldValue);
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.zip && errors.zip && (
                      <span className="errorMsg">{errors.zip}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="mt-3 mb-3 submitButton"
                  disabled={isSubmittingDraftStudent}
                  style={{ width: "45%", margin: "auto" }}
                  onClick={() =>
                    saveStudentAsDraft(studentInfo, (createdDoc) => {
                      onAddStudentSuccess(createdDoc);
                      onSecondStageModalClose();
                    })
                  }
                >
                  {isSubmittingDraftStudent ? (
                    <SubmitSpinner style={{ width: "1rem", height: "1rem" }} />
                  ) : (
                    <div className="w-100 position-relative">
                      <span className="buttonSpan">Save As Draft</span>
                    </div>
                  )}
                </button>

                <button
                  className="mt-3 mb-3 submitButton"
                  disabled={isSubmitting}
                  type="submit"
                  style={{ width: "45%", margin: "auto" }}
                >
                  {isSubmitting ? (
                    <SubmitSpinner style={{ width: "1rem", height: "1rem" }} />
                  ) : (
                    <div className="w-100 position-relative">
                      <span className="buttonSpan">
                        Save And Create Private Lesson
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </Form>

      </CustomModalBody>
    </CustomModal>
      )}
    </Formik>
  );
};

export default SecondStageModal;
