import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { Check2Square } from "@styled-icons/bootstrap/Check2Square";
import { SortAlphaDown } from "@styled-icons/bootstrap/SortAlphaDown";
import { ArrowRightSquareFill } from "@styled-icons/bootstrap/ArrowRightSquareFill";
import "./style.css";
import useModal from "src/hooks/useModal";
import CreatePrivateLessonModal from "../common/components/CreatePrivateLessonModal";
import {
  checkIfCompletedPrivateStudent,
  getAgeFromDate,
} from "src/utils/helpers";
import ViewStudentInfoModal from "../common/components/ViewStudentInfoModal";
import { StudentInfoContextProvider } from "src/contexts/StudentInfoContext";
import { parsePrivateLessonDates } from "src/utils/firebaseDatesParserFns";
import ViewParentInfoModal from "../common/components/ViewParentInfoModal";
import { ClickableTableCell } from "src/utils/shared/styled";
import moment from "moment";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import ConfirmationModal from "../common/components/ConfirmationModal";
import { LearningProfilesContext } from "./LearingProfiles";
import { useNavigate } from "react-router";
import store from "../../../stores/UserStore";

const Checkicon = styled(ArrowRightSquareFill)`
  color: #151515;
  width: 17px;
`;
const DeleteIcon = styled(DeleteOff)`
  width: 17px;
  color: #151515;
  cursor: pointer;
`;
const SortIcon = styled(SortAlphaDown)`
  color: #681e46;
  width: 35px;
  margin-left: 15px;
  cursor: pointer;
`;
function LearningStudentsView({ itemsPerPage, SetisToSortArr, HandleSort }) {
  const {
    students,
    instruments,
    programs,
    teachers,
    locations,
    lessons,
    parents,
    showIncompleteStudents,
  } = useContext(LearningProfilesContext);
  const navigate = useNavigate();

  console.log("students", students)
  const { permaDeleteUserAccount } = useFirebaseFns();

  const [pressedStudent, setPressedStudent] = useState({});
  const [currentParentId, setCurrentParentId] = useState("");
  const [loading, setIsLoading] = useState(false);

  const currentParent = useMemo(() => {
    if (!currentParentId) return;
    return parents?.find(({ id }) => id === currentParentId);
  }, [parents, currentParentId]);

  const createPLModalData = useModal();
  const viewAllUserDataModalData = useModal();
  const parentInfoModalData = useModal();
  const deleteStudentModalData = useModal();
  const { setLearningUser } = store;

  const navigateToDetails = (user, id) => {
    setLearningUser(user)
    navigate(`/dashboard/learning-profiles/${id}`);
  }

  const renderActions = (index, isCompletedStudent, id, user) => {

    return (
      <div className="userActions">
        <div className="d-flex gap-2">
          {isCompletedStudent && (
            <Checkicon
              className="icon"
              onClick={() => navigateToDetails(user, id)}
            />
          )}
        </div>
      </div>
    );
  };
  const onDeleteStudentApprove = async () => {
    if (!pressedStudent) return;

    try {
      setIsLoading(true);

      await permaDeleteUserAccount(pressedStudent?.id);
      deleteStudentModalData.closeModal();
      setPressedStudent({});
      toast.success("Deleted Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const onDeleteStudentCancel = () => {
    deleteStudentModalData.closeModal();
    setPressedStudent({});
  };

  const curr = students?.map((student, index) => {
    const {
      id,
      systemID,
      fullName,
      primaryContactEmail,
      secondaryContactEmail,
      primaryPhone,
      birthday,
    } = student;
    const ageStr = birthday
      ? getAgeFromDate(moment(birthday, "YYYY-MM-DD").toDate())
      : "N/A";
    const isCompletedStudent = checkIfCompletedPrivateStudent(student);

    const primaryParentUser = parents.find(
      ({ primaryEmail }) =>
        primaryContactEmail && primaryEmail === primaryContactEmail
    );
    const secondaryParentUser = parents.find(
      ({ primaryEmail }) =>
        secondaryContactEmail && primaryEmail === secondaryContactEmail
    );


    return (
      <tr key={id} id={id} className="TableRow">
        <td
          className="name-cell"
          onClick={() => {
            setPressedStudent(students[index]);
            viewAllUserDataModalData.openModal();
          }}
        >
          {fullName || "N/A"}
        </td>
        <td>{systemID || "N/A"}</td>
        <td>{ageStr}</td>
        {primaryParentUser ? (
          <ClickableTableCell
            onClick={() => {
              setCurrentParentId(primaryParentUser.id);
              parentInfoModalData.openModal();
            }}
          >
            {primaryContactEmail}
          </ClickableTableCell>
        ) : (
          <td>{primaryContactEmail}</td>
        )}
        {secondaryParentUser ? (
          <ClickableTableCell
            onClick={() => {
              setCurrentParentId(secondaryParentUser.id);
              parentInfoModalData.openModal();
            }}
          >
            {secondaryContactEmail}
          </ClickableTableCell>
        ) : (
          <td>{secondaryContactEmail}</td>
        )}{" "}
        <td>{primaryPhone || "N/A"}</td>
        <td>
          <div className="Iconsactions">
            {renderActions(index, isCompletedStudent, id, student)}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="tableContainer">
        <table className="studentTables">
          <thead>
            <tr className="TableRow">
              <th>
                <div className="HeadContainer">
                  <>Name of Student</>
                  <SortIcon onClick={HandleSort} />
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>ID</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Age</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Primary Contact Email</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Secondary Contact Email</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Primary Phone</>
                </div>
              </th>
              <th className="actions">
                <div className="HeadContainer">
                  <>Actions</>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{curr}</tbody>
        </table>
      </div>

      {pressedStudent && createPLModalData.isModalOpen && (
        <CreatePrivateLessonModal
          modalData={createPLModalData}
          studentId={pressedStudent?.id}
          providedInitialData={{
            instruments: Object.values(instruments || []),
            locations,
            programs: Object.values(programs || []),
            teachers: Object.values(teachers || []),
          }}
          headerTitle="Add New Instrument"
        />
      )}
      {parentInfoModalData.isModalOpen && currentParent && (
        <ViewParentInfoModal
          modalData={parentInfoModalData}
          parentUser={currentParent}
        />
      )}
      <StudentInfoContextProvider
        providedInitialData={{
          privateLessons: Object.values(lessons).map((lsn) =>
            parsePrivateLessonDates(lsn)
          ),
          instruments: Object.values(instruments),
          programs: Object.values(programs),
          teachers: Object.values(teachers),
          students: students,
          locations: locations,
        }}
      >
        <ViewStudentInfoModal
          modalData={viewAllUserDataModalData}
          currentStudent={pressedStudent}
        />
      </StudentInfoContextProvider>

      {pressedStudent && deleteStudentModalData.isModalOpen && (
        <ConfirmationModal
          modalData={deleteStudentModalData}
          onApprove={onDeleteStudentApprove}
          onCancel={onDeleteStudentCancel}
          isSubmitting={loading}
          title="Permanently Delete User ?"
          approveBtnText="Delete User"
          cancelBtnText="Cancel"
        />
      )}
    </>
  );
}
export default LearningStudentsView;
