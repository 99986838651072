import {
  MediaViewerContainer,
  VideoContainer,
} from "../../styled-components/teacherLibraryStyledComponents";
import ReactPlayer from "react-player";
import React, { useContext, useState } from "react";
import { ViewItemModalContext } from "../common/libraryContext";
import MediaControls from "./MediaControls";

const VideoViewer = ({ video = "", title = '', showClose = true}) => {
  const { close } = useContext(ViewItemModalContext);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playedDuration, setPlayedDuration] = useState(0);
  const [duration, setDuration] = useState(0);
  const [url, setUrl] = useState(video);

  return (
    <MediaViewerContainer className={"d-flex flex-column video-container"}>
      {showClose && <div className={"ms-2 close-container video-close"}>
        <button className="close" onClick={close}>
          <i className="fas fa-times" />
        </button>
      </div>}
      <VideoContainer
        className={"d-flex align-items-center justify-content-center mb-3"}
      >
        <ReactPlayer
          url={url}
          playing={isPlaying}
          played={playedDuration}
          width={"100%"}
          height={"100%"}
          fallback={
            <div className={"alert alert-danger"}>Error loading this video</div>
          }
          onReady={() => {
            setIsReady(true);
          }}
          onDuration={(d) => {
            setDuration(d);
          }}
          onProgress={({ played }) => {
            setPlayedDuration(played);
          }}
        />
      </VideoContainer>
      <MediaControls
        className={"video-player"}
        isReady={isReady}
        playedDuration={playedDuration}
        setIsPlaying={setIsPlaying}
        mediaUrl={url}
        setMediaUrl={setUrl}
        duration={duration}
        originalUrl={video}
        title={title}
        setPlayedDuration={setPlayedDuration}
      />
    </MediaViewerContainer>
  );
};
export default VideoViewer;
