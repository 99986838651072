/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext, useMemo } from "react";
import styled from "styled-components";
import "./style.css";
import { Check2Square } from "@styled-icons/bootstrap/Check2Square";
import { ListCheck } from "@styled-icons/bootstrap/ListCheck";
import useModal from "src/hooks/useModal";
import CreatePrivateLessonModal from "../common/components/CreatePrivateLessonModal";
import {
  checkIfCompletedPrivateStudent,
  getAgeFromDate,
} from "src/utils/helpers";
import ViewStudentInfoModal from "../common/components/ViewStudentInfoModal";
import { StudentInfoContextProvider } from "src/contexts/StudentInfoContext";
import { parsePrivateLessonDates } from "src/utils/firebaseDatesParserFns";
import { ClickableTableCell } from "src/utils/shared/styled";
import ViewParentInfoModal from "../common/components/ViewParentInfoModal";
import moment from "moment";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import ConfirmationModal from "../common/components/ConfirmationModal";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { LearningProfilesContext } from "./LearingProfiles";

const Checkicon = styled(Check2Square)`
  color: #151515;
  width: 17px;
`;
const DeleteIcon = styled(DeleteOff)`
  width: 17px;
  color: #151515;
  cursor: pointer;
`;
const ListCheckicon = styled(ListCheck)`
  color: #151515;
  width: 17px;
`;
const LearningStudentsSearch = ({ searchUser, filterdUsers }) => {
  const {
    instruments,
    programs,
    teachers,
    locations,
    lessons,
    students,
    parents,
    showIncompleteStudents,
  } = useContext(LearningProfilesContext);
  const { permaDeleteUserAccount } = useFirebaseFns();

  const tableHead = (
    <thead>
      <tr className="TableRow">
        <th>
          <div className="HeadContainer">
            <>Name of Student</>
          </div>
        </th>
        <th>
          <div className="HeadContainer">
            <>ID</>
          </div>
        </th>
        <th>
          <div className="HeadContainer">
            <>Age</>
          </div>
        </th>
        <th>
          <div className="HeadContainer">
            <>Primary Contact Email</>
          </div>
        </th>
        <th>
          <div className="HeadContainer">
            <>Secondary Contact Email</>
          </div>
        </th>
        <th>
          <div className="HeadContainer">
            <>Primary Phone</>
          </div>
        </th>
        <th className="actions">
          <div className="HeadContainer">
            <>Actions</>
          </div>
        </th>
      </tr>
    </thead>
  );
  const [pressedStudent, setPressedStudent] = useState({});
  const [currentParentId, setCurrentParentId] = useState("");
  const [loading, setIsLoading] = useState(false);

  const currentParent = useMemo(() => {
    if (!currentParentId) return;
    return parents?.find(({ id }) => id === currentParentId);
  }, [parents, currentParentId]);

  const createPLModalData = useModal();
  const viewAllUserDataModalData = useModal();
  const parentInfoModalData = useModal();
  const deleteStudentModalData = useModal();

  const renderActions = (index, isCompletedStudent) => (
    <div className="userActions">
      <div className="d-flex gap-2">
        {isCompletedStudent && (
          <Checkicon
            className="icon"
            onClick={() => {
              setPressedStudent(filterdUsers[index]);
              createPLModalData.openModal();
            }}
          />
        )}
        {showIncompleteStudents && (
          <DeleteIcon
            className="icon"
            onClick={() => {
              setPressedStudent(filterdUsers[index]);
              deleteStudentModalData.openModal();
            }}
          />
        )}
      </div>
    </div>
  );

  const onDeleteStudentApprove = async () => {
    if (!pressedStudent) return;

    try {
      setIsLoading(true);

      await permaDeleteUserAccount(pressedStudent?.id);
      deleteStudentModalData.closeModal();
      setPressedStudent({});
      toast.success("Deleted Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const onDeleteStudentCancel = () => {
    deleteStudentModalData.closeModal();
    setPressedStudent({});
  };
  return (
    <>
      <div className="tableContainer">
        <table className="studentTables">
          {tableHead}
          <tbody>
            {searchUser ? (
              <>
                {filterdUsers.length === 0 ? (
                  <>
                    <tr className="TableRow">
                      <td>Not Found</td>
                      <td>Not Found</td>
                      <td>Not Found</td>
                      <td>Not Found</td>
                      <td>Not Found</td>
                      <td>Not Found</td>
                      <td className="Iconsactions">Not Found</td>
                    </tr>
                  </>
                ) : (
                  filterdUsers.map((student, index) => {
                    const {
                      id,
                      systemID,
                      fullName,
                      // primaryContactName,
                      primaryPhone,
                      primaryContactEmail,
                      secondaryContactEmail,
                      primaryEmail,
                      birthday,
                    } = student;
                    const ageStr = birthday
                      ? getAgeFromDate(moment(birthday, "YYYY-MM-DD").toDate())
                      : "N/A";
                    const isCompletedStudent =
                      checkIfCompletedPrivateStudent(student);

                    const primaryParentUser = parents.find(
                      ({ primaryEmail }) =>
                        primaryContactEmail &&
                        primaryEmail === primaryContactEmail
                    );
                    const secondaryParentUser = parents.find(
                      ({ primaryEmail }) =>
                        secondaryContactEmail &&
                        primaryEmail === secondaryContactEmail
                    );

                    return (
                      <tr key={id} id={id} className="TableRow">
                        <td
                          className="name-cell"
                          onClick={() => {
                            setPressedStudent(filterdUsers[index]);
                            viewAllUserDataModalData.openModal();
                          }}
                        >
                          {fullName}
                        </td>
                        <td>{systemID || "N/A"}</td>
                        <td>{ageStr}</td>

                        {primaryParentUser ? (
                          <ClickableTableCell
                            onClick={() => {
                              setCurrentParentId(primaryParentUser.id);
                              parentInfoModalData.openModal();
                            }}
                          >
                            {primaryContactEmail}
                          </ClickableTableCell>
                        ) : (
                          <td>{primaryContactEmail}</td>
                        )}
                        {secondaryParentUser ? (
                          <ClickableTableCell
                            onClick={() => {
                              setCurrentParentId(secondaryParentUser.id);
                              parentInfoModalData.openModal();
                            }}
                          >
                            {secondaryContactEmail}
                          </ClickableTableCell>
                        ) : (
                          <td>{secondaryContactEmail}</td>
                        )}
                        <td>{primaryPhone}</td>
                        <td>
                          <div className="Iconsactions">
                            {renderActions(index, isCompletedStudent)}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </>
            ) : null}
          </tbody>
        </table>
      </div>

      {pressedStudent && createPLModalData.isModalOpen && (
        <CreatePrivateLessonModal
          modalData={createPLModalData}
          studentId={pressedStudent?.id}
          providedInitialData={{
            instruments: Object.values(instruments || []),
            locations,
            programs: Object.values(programs || []),
            teachers: Object.values(teachers || []),
          }}
          headerTitle="Add New Instrument"
        />
      )}

      <StudentInfoContextProvider
        providedInitialData={{
          privateLessons: Object.values(lessons).map((lsn) =>
            parsePrivateLessonDates(lsn)
          ),
          instruments: Object.values(instruments),
          programs: Object.values(programs),
          teachers: Object.values(teachers),
          students: students,
          locations: locations,
        }}
      >
        <ViewStudentInfoModal
          modalData={viewAllUserDataModalData}
          currentStudent={pressedStudent}
        />
      </StudentInfoContextProvider>

      {parentInfoModalData.isModalOpen && currentParent && (
        <ViewParentInfoModal
          modalData={parentInfoModalData}
          parentUser={currentParent}
        />
      )}
      {pressedStudent && deleteStudentModalData.isModalOpen && (
        <ConfirmationModal
          modalData={deleteStudentModalData}
          onApprove={onDeleteStudentApprove}
          onCancel={onDeleteStudentCancel}
          isSubmitting={loading}
          title="Permanently Delete User ?"
          approveBtnText="Delete User"
          cancelBtnText="Cancel"
        />
      )}
    </>
  );
};
export default LearningStudentsSearch;
