const PROFILE_CATEGORIES = [
  { value: "teacher_report", label: "Teacher Report" },
  { value: "teacher_assistance", label: "Teacher Assistance" },
  { value: "leadership", label: "Leadership" },
  { value: "performance", label: "Performance" },
  { value: "creative", label: "Creative" },
  { value: "award", label: "Award" },
  { value: "social", label: "Social Media" },
  { value: "sharing", label: "Sharing" },
];

export { PROFILE_CATEGORIES };
